
// import { loadStripe  } from "@stripe/stripe-js"
// import { Config } from "../../Config";
import {Typography} from "@mui/material";


// let stripePromise;
//const getStripe = async () => {
//     if (!stripePromise) {
//         const stripe = await loadStripe(`${Config.pubKey}`);
//         console.log("Stripe Publishable Key:", stripe);
//         stripePromise = Promise.resolve(stripe);
//     }
//     return stripePromise;
// };


// Donate Boxes

export const DonateBox1 = ({ handleDonate }) => {
	return (
		<div className="donate-box">
			<Typography className="inputDonationTitle">
				Realiza aqui tu contribución
			</Typography>
			<button
				id="btn-colabora"
				className="button button2"
				onClick={handleDonate}
			>
				Donación
			</button>
		</div>
	);
};

export const DonateBox2 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/fZe3gb5aE46kdOMfYZ`;
    }
    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Aparición en los creditos del documental</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>5 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Aparecerás como mecenas del proyecto en los créditos del documental.</p>
                    </div>
                    
                    <p className="cardBackers1">Entrega aproximada</p>
                    <p className="">Dic 2024</p>
                    
                    <div className="carDivision">
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 5 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox3 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/14k3gb1YscCQ9yw4gi`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Aparición en créditos &nbsp; &nbsp; &nbsp; + Copia digital</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>15 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Aparecerás como mecenas en los créditos del documental y te mandaremos linka ver la película es de su estreno online.</p>
                    </div>
                    <p className="cardBackers1">Entrega aproximada</p>
                    <p className="">Dic 2024</p>
                    {/* <div className="cardContenedor">
                        <div className="carDivision">
                            <p className="cardBackers1">Backers</p>
                            <p>0</p>
                       
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Entrega aproximada</p>
                            <p className="cardBackers2">Dic 2024</p>
                        </div>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 15 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox4 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/eVag2X7iMauIfWU9AD`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Invitación Estreno en cines + Charlas</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>30 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Tendrás dos entradas para ver el documental en cines. Donde se podrá hacer preguntas a algunos de los protagonistas del documental.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p> */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                            <p>Dic 2024</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo España</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 30 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox5 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/6oEdUPcD646k6mk288`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Camiseta / T-Shirt The Blue Dot</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>30 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Camiseta con el código QR directo al documental para que lleves el mensaje a todas partes donde vayas.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p> */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                            <p>15 días</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo en ciertos paises</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>15 días</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 30 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox6 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/5kAdUP7iM7iwfWU6os`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Cartel cines the big reset firmado por W</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>40 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Recibirás el cartel original que se usó en los cines donde se proyectó el anterior documental, The Big reset Movie, con tu nombre y firmado por el director W.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                            <p>15 días</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo en ciertos paises</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>15 días</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 40 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox7 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/14k03Z5aE9qEbGEaEJ`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Pack Completo</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>50 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Invitación para dos personas en el estreno en cines donde podrán asistir algunos de los protagonistas + aparecer en los títulos de crédito + link para ver el documental antes de su estreno online.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                           {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo España</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 50 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox8 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/28ocQL5aEcCQcKI9AG`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Sudadera The Blue Dot</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>50 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Sudadera con el código QR directo al documental para que lleves el mensaje allá donde vayas.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                        <p>15 días</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo en ciertos paises</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>15 días</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 50 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox9 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/dR62c7dHa5aobGE14b`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Camiseta + Invitación al Estreno en Cine</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>60 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Camiseta The Blue Dot + 2 Invitaciones al estreno en cines, donde podran acudir algunos de los protagonistas del documental.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo España</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 60 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox10 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/6oEg2X32w1Yc4ec8wE`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Pack Completo + Sudadera</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>100 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Sudadera The Blue Dot + 2 Invitaciones para asistir al estreno del documental donde podran asistir algunos de los protagonistas del documental + Link para ver el documental antes de su estreno online + Aparecer en los títulos de crédito.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                            <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Envío a</p>
                            <p className="cardBackers2">Solo España</p>
                        </div>
                    </div>
                    {/* <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproximada</p>
                        <p>Dic 2024</p>
                    </div> */}
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 100 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox11 = () => {
   const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/7sIbMHgTmeKY7qoaEN`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Pon Tu Logo En el Documental</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>500 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Recompensa dirigida a organizadores o colectivos. Aparecerá tu logo en la pelicula, en la web del proyecto y en las comunicaciones que hagamos en medios como patrocinador oficial del documental</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Entrega Aproxímada</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 500 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

// Tickets
// Function to handle Personal
export const TicketBox1 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIYeYTfPigT68usbIT`;
    }
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">15 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
						<p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1 persona</strong> puede ver The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox2 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/cN2cQL1Ys32g9yw5kw`;
    }
   
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">30 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>2 personas</strong> pueden ver The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox3 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/eVacQL1YseKY7qo14h`;
    }

    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">45 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>3 personas</strong> pueden ver The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox4 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/eVa2c732w32gh0Y6oC`;
    }

    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">60 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>4 personas</strong> pueden ver The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox5 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/7sI03ZdHacCQfWU6oD`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">75 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>5 personas</strong> pueden ver The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox6 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/fZe3gb1YseKY7qo8wM`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">105 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text">
                            <i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> 
                            <strong>7 personas</strong> pueden ver The Blue Dot Movie 
                        </p>
                       
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox7 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIY6sn5aEfP27qo4gx`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">150 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>10 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox8 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/8wM6snauY7iw4ec00i`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">225 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>15 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox9 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIY5ojdHagT69ywfZh`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">300 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>20 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox10 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/9AQg2XgTm1Yc9yw7sM`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">525 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>35 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}


// Ticket hanldle Cines
export const TicketBox11 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/9AQg2XgTm1Yc9yw7sM`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">525 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>35 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox12 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIY4kf1YsgT65ig28t`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">1,125 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>75 personas</strong> pueden ver The Blue Dot Movie </p>

					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox13 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/cN203ZcD66es9yw9AW`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">1,500 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>100 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox14 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3cs9Ez1Ys32g4ec8wT`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">3,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>200 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox15 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/6oE9Ez8mQfP25igfZm`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">5,250 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>350 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}


// Ticket handle Grupos
export const TicketBox16 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3cs4kfcD6cCQ3a87sR`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">11,250 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>750 personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox17 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/4gw5oj6eIfP23a85kK`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">15,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1.0k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox18 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/00g4kf5aEbyMh0Y9B1`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">22,500 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1.5k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox19 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIY03Z8mQ6es4ecbJa`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">30,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>2.0k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox20 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/eVabMHdHa0U8aCA5kN`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">45,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>3.0k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox21 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/4gwdUPdHa32g9yw14y`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">60,000<i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>4.0k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox22 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/6oEeYTbz28mAaCA9B5`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">75,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>5.0k personas</strong> pueden ver The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Selecciona </button>
				</div>
			</div>
		</div>
    );
}