import '../assets/css/App.css'
import { useState } from 'react'


export default function FaqsEng() {

    const [selected, setSelected] = useState(null)


    const toggle = i => {
        if (selected === i) {
            return setSelected(null)
        }

        setSelected(i)
    }



    return (
        <div id='Faqs'>
            <div className='wrapper'>

                <div className='Preguntasfreq'>Frequently Asked Questions</div>
                <div className="accordionFaqs">

                    {Faqdata.map((item, i) => (
                        <div className='item'>
                            <div className='header' onClick={() => toggle(i)}>
                                <h5><strong>{item.titulo}</strong></h5>
                                <span>{selected === i ? '-' : '+'}</span>
                            </div>
                            <div className={selected === i ? 'body show' : 'body'}>
                                <p>{item.contenido}</p>
                                <a href={item.enlace}><p><u>{item.url}</u></p></a>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </div>
    )

}

const Faqdata = [
    {
        titulo: '¿What is The Blue Dot?',
        contenido: '"The Blue Dot" is the sequel to the documentary "The Big Reset Movie" which has been widely censored worldwide for providing in-depth explanations of all the events we have experienced since 2020. The objective of this documentary is to continue informing the public about what is really happening on the planet.',
        
    },
    {
        titulo: 'In what ways can I contribute to this project?',
        contenido: 'Your participation in spreading this documentary is very important. Without the collaboration of thousands of people around the world, this project would be completely impossible. You can find all the ways to share this message here.',
        url: 'How can I help share the message?',
        enlace: 'https://thebluedotmovie.com/como-ayudar',
    },
    {
        titulo: 'How is the price for the cinema ticket invitation determined?',
        contenido: 'The price of cinema tickets in Spain will remain at €15 per person. To screen this documentary, we have to rent cinema by cinema, hall by hall, just like we did in the previous project, The Big Reset Movie. You can see everything here (@tbdmovie). The Blue Dot Movie would never pass the culture filter because it depends on the same organizations that have been manipulating society since 2020. Part of the ticket proceeds go towards the documentary production expenses, while another part covers the rental of the venue and personnel, including security, access attendants, photographers, and audiovisual equipment. Additionally, these contributions cover the travel and accommodation expenses of the protagonists, who, depending on their availability, may attend screenings scheduled in various cities. The cinema tour will start in Spain, but it may be extended to other countries. We will announce everything well in advance on this website.',
    },
    {
        titulo: 'How will the Pay It Forward money be used?',
        contenido: 'We have adopted the model of purchasing cinema ticket invitations for others, which has been implemented by the production company Angel Studios in the United States. This approach has worked very well for their film "Sound of Freedom" which has broken all box office records and become the most-watched movie in US theaters in 2023, despite facing heavy censorship. We are living in times of change, and this is a way of asking society what it truly wants to see.',
    },
    {
        titulo: 'Who will receive these complimentary invitations?',
        contenido: 'Once the documentary is finished filming and following the model of renting cinema halls that we implemented in the previous project, The Big Reset Movie, we will schedule screenings throughout the national territory. The goal is to have showings in every city, in every cinema, and all the time. When this cinema phase begins, we will also open the possibility for people who may not have the resources to purchase tickets to obtain them at no cost through this website.',
    },
    {
        titulo: 'How can my company or organization promote this documentary?',
        contenido: 'That was one of the ways we managed to make the previous project a reality, with the collaboration of companies and organizations. In the Collaborate section, you can find information on how to place your logo in the documentary. The film will have a global impact, and millions of people worldwide will see your companys or organizations logo as a sponsor of this project.',
    },
    {
        titulo: 'Are you interested in acquiring cinema screening invitations for your business, organization, or employees?',
        contenido: 'Every person counts. Your company or organization can help us reach our goal and make this project a reality. Please write to this email, and we will send you a quote.',
        url: 'Email : hola@thebigresetmovie.com ',
        enlace: 'mailto:hola@thebigresetmovie.com',
    },
    {
        titulo: 'How can I organize a private screening?',
        contenido: 'Once the documentary is scheduled to premiere in cinemas, private screenings can be organized for groups or organizations. You can write to this email, and we will respond as soon as possible.',
        url: 'Email : hola@thebigresetmovie.com ',
        enlace: 'mailto:hola@thebigresetmovie.com',
    },
    {
        titulo: 'Why are we conducting the crowdfunding on this page?',
        contenido: 'If you are familiar with the previous project, you will know that the crowdfunding for the documentary The Big Reset Movie was done on the Kickstarter platform, the worlds number one crowdfunding website. This time, The Blue Dot has also been censored on Kickstarter. Its curious that they allowed us to launch a project about the psychopandemic in 2021, and in 2023, they vetoed a documentary about Agenda 2030. According to their community guidelines, "Projects that encourage discrimination or intolerance towards marginalized groups are not allowed." An obvious excuse to prevent us from carrying it out ontheir platform. There is a lot of money involved in that colorful Agenda.',
    },
    {
        titulo: 'How to financially support finishing the project and bringing it to cinemas?',
        contenido: 'For now, we have filmed the first part of the project, featuring some Spanish protagonists. However, to build a global narrative, we need to conduct interviews with professionals from various fields in different countries. That s why your collaboration and that of thousands of people worldwide is necessary. Contributions range from 1€ to whatever amount you believe you can contribute to make this project a reality.',
    },
    {
        titulo: 'When will the documentary be released?',
        contenido: 'The realise date of the documentary is tentative, as it largely depends on scheduling         interviews with hard-to-reach individuals, many of whom are under threats. Therefore,        it s not an easy task to provide an exact release date. The goal is to potentially screen it         by the end of 2024. We will keep you informed of the documentary s progress through the email provided by all the sponsors. Thank you very much for believing in all of this. ;)',
    },

    {
        titulo: 'Where can I watch The Big Reset Movie?',
        contenido: 'The censorship that the previous documentary has endured and continues to face is severe. YouTube, Vimeo, and similar platforms instantly remove it. There         were moments in 2022 when you could not even mention the name of the website in Instagram posts or private messages between individuals; they would delete them. They had a good excuse before, a deadly virus was supposedly circulating among the population. And now that this virus is supposedly no longer a threat, why do they continue to censor it? The answer is quite simple. This whole story has never been about a virus. You can watch the full documentary in Spanish/English/French and draw your own conclusions on the official project website.',
        url: 'Ver: Web The Big Reset',
        enlace: 'https://thebigresetmovie.com',
    }

]
