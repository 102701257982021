import React, { Component } from 'react'

import Disidenteimg from '../assets/images/CENSURAALDISIDENTE.jpg'



class DisidenteEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Lacensuraaldisidente">Censorship of dissidents</div>
                
                <br/>
                <img src={Disidenteimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">We have witnessed this starkly during the psychopandemic. Anyone who did not align with the status quo was immediately labeled as a conspiracy theorist or denier.<strong> As the narrative of the virus and its mutations became exhausted, the current trend is to vehemently attack anyone who disagrees on one of the key points to achieve the objectives of the Agenda: human-induced climate change.</strong></p>

            </div>
        </div>
        );

    }
}

export default DisidenteEng;