import React, { Component } from 'react'

import Calentamientoimg from '../assets/images/CALENTAMIENTOGLOBAL.jpg'



class Calentamientoglobal extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Elcalentamientoglobal">Calentamiento Global</div>
                
                <br/>
                <img src={Calentamientoimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl"><strong>El relato del calentamiento global generado por el CO2 proveniente de la actividad humana, se ha convertido en un dogma que no permite debate alguno,</strong> a pesar de que infinidad de físicos, climatólogos, Premios Nobel, y máximos expertos sobre la materia a nivel mundial afirmen lo contrario.<strong> Adornado como ciencia, se presenta como una nueva religión, el nuevo totalitarismo ecológico del S.XXI.</strong></p>

            </div>
        </div>
        );

    }
}

export default Calentamientoglobal;