
import React, { useState } from "react";
import Header from "./Header";
import Progress_bar from "./Progress_bar";
import Trailer from "./Trailer";
import Sabermas from "./Sabermas";
import Agenda from "./Agenda";
import Faqs from "./Faqs";
import Falsapandemia from "./Falsapandemia";
import Massmedia from "./massmedia";
import Disidente from "./Disidente";
import Calentamientoglobal from "./Calentamientoglobal";
import Dictaduraecologica from "./Dictaduraecologica";
import Transhumanismo from "./Transhumanismo";
import Futurohumanidad from "./Futurohumanidad";
import Protagonistas from "./Protagonistas";
import Elobjetivo from "./Elobjetivo";
import Lasrecompensas from "./Lasrecompensas";
import Fondos from "./Fondos";
import Footer from "./Footer";


const Principal = () => {
    const [showMuiAccordion, setShowMuiAccordion] = useState(false);
	const [showMuiEntradas, setShowMuiEntradas] = useState(false);
	const [showMuiCines, setShowMuiCines] = useState(false);
	const [showMuiGroup, setShowMuiGroup] = useState(false);

    return(
        <div className="App">
        <Header />
        <Trailer />
        <Progress_bar
            showMuiAccordion={showMuiAccordion}
            setShowMuiAccordion={setShowMuiAccordion}
            showMuiEntradas={showMuiEntradas}
            setShowMuiEntradas={setShowMuiEntradas}
            showMuiCines={showMuiCines}
            setShowMuiCines={setShowMuiCines}
            showMuiGroup={showMuiGroup}
            setShowMuiGroup={setShowMuiGroup}
        />
        <Faqs />
        <Sabermas />
        <Agenda />
        <Falsapandemia />
        <Massmedia />
        <Disidente />
        <Calentamientoglobal />
        <Dictaduraecologica />
        <Transhumanismo />
        <Futurohumanidad />
        <Protagonistas />
        <Elobjetivo />
        <Lasrecompensas />
        <Fondos />
        <Footer />
        <header className="App-header"></header>
    </div>
    );
}
export default Principal;