import React, { Component, useState } from 'react'
import HeaderEng from "./HeaderEng";
import TrailerEng from './TrailerEng';
import Progress_barEng from "./Progress_barEng";
import FaqsEng from './FaqsEng';
import SabermasEng from './SabermasEng';
import AgendaEng from './AgendaEng';
import FalsapandemiaEng from './FalsapandemiaEng';
import MassmediaEng from './massmediaEng';
import DisidenteEng from './DisidenteEng';
import CalentamientoglobalEng from './CalentamientoglobalEng';
import DictaduraecologicaEng from './DictaduraecologicaEng';
import TranshumanismoEng from './TranshumanismoEng';
import FuturohumanidadEng from './FuturohumanidadEng';
import ProtagonistasEng from './ProtagonistasEng';
import ElobjetivoEng from './ElobjetivoEng';
import LasrecompensasEng from './LasrecompensasEng';
import FondosEng from './FondosEng';
import Footer from './Footer';
import FooterEng from './FooterEng';



const PrincipalENG = () => {
    const [showMuiAccordion, setShowMuiAccordion] = useState(false);
	const [showMuiEntradas, setShowMuiEntradas] = useState(false);
	const [showMuiCines, setShowMuiCines] = useState(false);
	const [showMuiGroup, setShowMuiGroup] = useState(false);

    return(
        <div className="App">
        <HeaderEng />
        <TrailerEng />
        <Progress_barEng
					showMuiAccordion={showMuiAccordion}
					setShowMuiAccordion={setShowMuiAccordion}
					showMuiEntradas={showMuiEntradas}
					setShowMuiEntradas={setShowMuiEntradas}
					showMuiCines={showMuiCines}
					setShowMuiCines={setShowMuiCines}
					showMuiGroup={showMuiGroup}
					setShowMuiGroup={setShowMuiGroup}
				/>
        <FaqsEng />
        <SabermasEng />
        <AgendaEng />
        <FalsapandemiaEng />
        <MassmediaEng />
        <DisidenteEng />
        <CalentamientoglobalEng />
        <DictaduraecologicaEng />
        <TranshumanismoEng />
        <FuturohumanidadEng />
        <ProtagonistasEng />
        <ElobjetivoEng />
        <LasrecompensasEng />
        <FondosEng/>
        <FooterEng />
       
        <header className="App-header"></header>
    </div>
    );
}
export default PrincipalENG;