import React, { Component } from 'react'

class HeaderEng extends Component {
    render() {
        return (
            <nav className="navbar navbar-inverse">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/">The Blue Dot</a>
                    </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="nav navbar-nav">
                            <li className="active"><a href="#">HOME</a></li>
                            <li><a href="#Sabermas">KNOW MORE</a></li>
                            <li><a href="#LasRecompensas">THE REGUARDS</a></li>
                            <li><a href="/ENG/help">HOW TO HELP</a></li>
                          
                         
                            
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            
                        </ul>
                    </div>
                </div>
            </nav>
        );

    }
}

export default HeaderEng;