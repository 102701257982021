import React, { Component } from 'react'

import Dictaduraecologicaimg from '../assets/images/LADICTADURAECOLÓGICA.jpg'
import Escenariosimg from '../assets/images/ESCENARIOSCC.jpg'



class Dictaduraecologica extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Ladictaduraecologica">La dictadura ecológica</div>
                
                <br/>
                <img src={Dictaduraecologicaimg} alt="Nature" className="responsive" />
                <h6>KLAUS SCHWAB EN UNA REUNIÓN DEL WEF</h6>
                <br/>
                <p id="carl">De nuevo con el WEF a la cabeza, la política “My carbon” apunta a una cantidad inimaginable de restricciones que amenazan las sociedades libres. <strong>En el documental mostraremos los escenarios que, si no hacemos nada para remediarlo, tienen diseñados para nosotros.</strong></p>
                <br/>
                <img src={Escenariosimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">Este programa de agenda verde se inspira en los días tiránicos de los cierres de COVID. <strong>Planes coercitivos de movilidad, Identidad digital, prohibición del dinero en efectivo y monedas programables, junto con la política Net Zero de impuesto al carbono, convertirían nuestra vida diaria en un escenario orwelliano.</strong></p>
            </div>
        </div>
        );

    }
}

export default Dictaduraecologica;