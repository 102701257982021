import React, { Component } from 'react'

class Header extends Component {
    render() {
        return (
            
            <nav className="navbar navbar-inverse">
                
                <div className="container-fluid">
                
                    <div className="navbar-header">
                   
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <a className="navbar-brand" href="/">The Blue Dot</a>
                        
                    </div>
                    <div id="cambiar-idioma">
                <a href="/ENG/Principal">ENG</a>
                </div>
                    <div className="collapse navbar-collapse" id="myNavbar">
                        <ul className="nav navbar-nav">
                            <li className="active"><a href="#">INICIO</a></li>
                            <li><a href="#Sabermas">SABER MÁS</a></li>
                            <li><a href="#LasRecompensas">LAS RECOMPENSAS</a></li>
                            <li><a href="/como-ayudar">COMO AYUDAR</a></li>
                            
                         
                            
                        </ul>
                        
                        <ul className="nav navbar-nav navbar-right">
                       
                        </ul>
                        
                    </div>
                   
                </div>
                
            </nav>
        );

    }
}

export default Header;