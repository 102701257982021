import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

import { TicketBox1 } from "./paymentBox/DonateBoxEng";
import { TicketBox2 } from "./paymentBox/DonateBoxEng";
import { TicketBox3 } from "./paymentBox/DonateBoxEng";
import { TicketBox4 } from "./paymentBox/DonateBoxEng";
import { TicketBox5 } from "./paymentBox/DonateBoxEng";
import { TicketBox6 } from "./paymentBox/DonateBoxEng";
import { TicketBox7 } from "./paymentBox/DonateBoxEng";
import { TicketBox8 } from "./paymentBox/DonateBoxEng";
import { TicketBox9 } from "./paymentBox/DonateBoxEng";
import { TicketBox10 } from "./paymentBox/DonateBoxEng";


// Function to handle font size change by screen size
const handleFontSizeChange = (theme) => ({
	[theme.breakpoints.down("sm")]: {
	  fontSize: "16px",
	  fontWeight: "500",
	},
	[theme.breakpoints.up("md")]: {
	  fontSize: "19px", 
	  fontWeight: "500",
	},
  });


const MuiEntradas = () => {
	// Info from DonateBox
	const [expanded, setExpanded] = useState(false);

	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};

	const theme = useTheme();

	return (
		<div className="wrapper">
			<div className="w3-container w3-gray" id="LosFondos"><i className="fa-solid fa-hand-holding-heart"></i>Pay It Forward</div>
			
			<p>Free invitations for others</p>
			<Accordion
				expanded={expanded === "panel1"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel1")}
			>
				<AccordionSummary
					id="panel1-header"
					aria-controls="panel1-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Individuals</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<hr/>
					<div className="accordionBorder">
						<TicketBox1 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox2 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
					  	<TicketBox3 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox4 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox5 /> 
					</div>
                    <hr/>

					<div className="accordionBorder">
						<TicketBox6 /> 
					</div>
                    <hr/>

					<div className="accordionBorder">
						<TicketBox7 /> 
					</div>
                    <hr/>

					<div className="accordionBorder">
						<TicketBox8 /> 
					</div>
                    <hr/>

					<div className="accordionBorder">
						<TicketBox9 /> 
					</div>
                    <hr/>

					<div className="accordionBorder">
						<TicketBox10 /> 
					</div>
				</AccordionDetails>
			</Accordion>

		</div>
	);
};

export default MuiEntradas;
