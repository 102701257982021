import React, { Component } from 'react'

import Massmediaimg from '../assets/images/LOSMASSMEDIA.jpg'



class Massmedia extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Losmassmedia">Los Mass Media</div>
                
                <br/>
                <img src={Massmediaimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">Sería imposible orquestar un golpe de estado desde arriba, como están intentando llevar a cabo estos grupos de poder, sin contar con la ayuda de los grandes medios de comunicación.<strong> Unos pocos fondos de inversión controlan los principales conglomerados mediáticos que forman la opinión pública mundial, en favor de las consignas globalistas.</strong></p>

            </div>
        </div>
        );

    }
}

export default Massmedia;