import React, { useEffect } from "react";
import "../../assets/css/ProgressBar.css";

export const ProgressBar = ({ progress }) => {
  useEffect(() => {
    const progressBar = document.getElementById("progress");
    progressBar.style.width = `${progress}%`;
  }, [progress]);

  return (
    <div id="glass">
      <div id="progress"></div>
    </div>
  );
};