import React, { Component } from 'react'

import Futurohumanidadimg from '../assets/images/ELFUTURODELAHUMANDAD.jpg'



class FuturohumanidadEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Elfuturodelahumanidad">The future of humanity</div>
                
                <br/>
                <img src={Futurohumanidadimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">These are, broadly speaking, the plans that these elites have designed for us and that the documentary will deal with. But that does not mean that everything will go as planned.<strong> Throughout the planet, there are brace voices that break away from the prevailing discourse, programmed by the mass media. These will be some of the protagonists of this project.</strong></p>

            </div>
        </div>
        );

    }
}

export default FuturohumanidadEng;