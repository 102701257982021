import React, { Component } from 'react'

import Massmediaimg from '../assets/images/LOSMASSMEDIA.jpg'



class MassmediaEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Losmassmedia">The Mass Media</div>
                
                <br/>
                <img src={Massmediaimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">It would be impossible to orchestrate a coup d'état from above, as these power groups are trying to do, without the help of the mainstream media.<strong> A few investment funds control the major media conglomerates that shape world public opinion, in favor of globalist slogans.</strong></p>

            </div>
        </div>
        );

    }
}

export default MassmediaEng;