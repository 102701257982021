import React, { Component } from 'react'

import Disidenteimg from '../assets/images/CENSURAALDISIDENTE.jpg'



class Disidente extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Lacensuraaldisidente">Censura al disidente</div>
                
                <br/>
                <img src={Disidenteimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">Lo hemos visto crudamente durante la psicopandemia. Todo aquél que no comulgase con el statu quo, fue directamente tachado de conspiranoico o negacionista.<strong> Agotado el relato del virus y sus mutaciones, la tendencia actual es atacar con vehemencia a todo aquél que discrepe sobre</strong> uno de los puntos principales para poder alcanzar los objetivos de la Agenda: <strong>el cambio climático generado por el ser humano.</strong></p>

            </div>
        </div>
        );

    }
}

export default Disidente;