import React, { Component } from 'react'

import RICHARDLINDIMG from '../assets/images/1.RICHARD-LINDZEN.jpg'
import PATRICKALBERTIMG from '../assets/images/2.PATRICKALBERTMOORE.jpg'
import PIERSCORBYNIMG from '../assets/images/3.PIERS-CORBYN.jpg'
import CATHERINEZIMG from '../assets/images/4.CATHERINE-AUSTIN-FITTS.jpg'
import CALINIMG from '../assets/images/5.CALIN-GEORGESCU.jpg'
import MIKEYEAIMG from '../assets/images/6.MIKEYEADON.jpg'
import CHRISTINEANDIMG from '../assets/images/7.CHISTINE-ANDERSON.jpg'
import ATHANASIUSIMG from '../assets/images/8.ATHANASIO.jpg'
import ROBERTIMG from '../assets/images/9.BOB-KENNEDY-JR.jpg'


class Elobjetivo extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="elobjetivo">El objetivo</div>
                <br/>
                <p id="carl"><strong>Pero para construir un relato global tendremos que salir fuera</strong> en búsqueda de más voces valientes que no están dispuestas a quedarse calladas mientras el 1% de la población intenta manipularnos con su coloreadas y tiránicas consignas. <strong>Estos serán también los protagonistas, la mayoría ya confirmados, para este segundo documental.</strong></p>
                <br/>
                <img src={RICHARDLINDIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>RICHARD LINDZEN:</strong> Físico atmosférico estadounidense y catedrático de Meteorología del Instituto Tecnológico de Massachusetts (MIT). Trabajó durante años en el Panel Intergubernamental sobre el Cambio Climático (IPCC) cuestionando sus conclusiones, criticando lo que ha llamado &quot;alarmismo climático&quot;.</p>
                <br/>
                <br/>
                <img src={PATRICKALBERTIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>PATRICK ALBERT MOORE:</strong> Consultor industrial y ex activista canadiense. Presidente de Greenpeace Canadá hasta el año 1986, cuando abandonó el cargo por cuenta propia afirmando que el movimiento ambiental &quot;abandonó la ciencia y la lógica en favor de la emoción y el sensacionalismo&quot;.</p>
                <br/>
                <br/>
                <img src={PIERSCORBYNIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>PIERS CORBYN:</strong> Meteorólogo, astrofísico y empresario inglés. Miembro de la Royal Geographical Society y fundador de WeatherAction. Sus estudios se basan en la&quot;técnica del clima solar&quot;, afirmando que cualquier incremento de temperatura se debe al sol, y no al CO2.</p>
                <br/>
                <br/>
                <img src={CATHERINEZIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CATHERINE AUSTIN FITTS:</strong> Banquera de inversiones y ex funcionaria pública estadounidense. Experta en los mecanismos financieros y geopolíticos ligados al Gran Reseteo, además de una gran conocedora del funcionamiento de las monedas digitales de los Bancos Centrales (CBDC).</p>
                <br/>
                <br/>
                <img src={CALINIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CALIN GEORGESCU:</strong> Ex Director Ejecutivo de las Naciones Unidas y ex Presidente del Centro de Investigación Europeo del Club de Roma. Georgescu describe el proceso de infiltración y toma global de las Naciones Unidas por parte de oligarcas, en particular Klaus Schwab y el Foro Económico Mundial.</p>
                <br/>
                <br/>
                <img src={MIKEYEAIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>MIKE YEADON:</strong> Destacó como investigador de alergias y vías respiratorias durante 16 años en Pfizer y asumió altas posiciones ejecutivas en la empresa. Se ha convertido en un icono a nivel mundial en la lucha contra los intereses globalistas.</p>
                <br/>
                <br/>
                <img src={CHRISTINEANDIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CHRISTINE ANDERSON:</strong> Miembro del Parlamento Europeo desde 2019. Fuerte defensora de los derechos humanos, ha llegado a comparar la situación que se vivió en la pandemia con la Alemania nazi.</p>
                <br/>
                <br/>
                <img src={ATHANASIUSIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>ATHANASIUS SCHNEIDER:</strong> Obispo de la Iglesia Católica y miembro de los Canónigos Regulares de la Santa Cruz. Crítico con algunas políticas implementadas por el Papa Francisco, Athanasius ha expresado públicamente su oposición a la dictadura sanitaria que instituciones como la OMS están intentando implementar.</p>
                <br/>
                <br/>
                <img src={ROBERTIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>ROBERT KENNEDY JR:</strong> Sobrino del presidente de los EEUU John Fitzgerald Kennedy y ahora candidato a la presidencia por el partido demócrata. Ha llegado a afirmar que la campaña de inoculación mundial de ARNm es un proyecto militar del Pentágono iniciado en 2012.</p>
                <br/>
                
                

            </div>
        </div>
        );

    }
}

export default Elobjetivo;


