import React, { Component } from 'react'

import ANTONIOIMG from '../assets/images/ANTONIO-HUERTAS.jpg'
import BENITOMUROSIMG from '../assets/images/BENITO-MUROS.jpg'
import CARLOSASTIZIMG from '../assets/images/CARLOS-ASTIZ.jpg'
import CRISTINAMJIMENEZIMG from '../assets/images/CRISTINA-MJIMENEZ.jpg'
import FERNANDO1IMG from '../assets/images/FERNANDO-1.jpg'
import FRANCESIMG from '../assets/images/FRAM-FRANCESC-3.jpg'
import JORDIPIGEMIMG from '../assets/images/JORDI-PIGEM-3.jpg'
import JRFERRNADISIMG from '../assets/images/JR-FERRNADIS-4.jpg'
import JUANDECASTROIMG from '../assets/images/JUAN-A-CASTRO-1.jpg'
import LORENZORAMIREZIMG from '../assets/images/LORENZO-RAMIREZ.jpg'
import LOURDESIMG from '../assets/images/LOURDES-RELLOSO-2.jpg'
import MANGELIMG from '../assets/images/M.ANGEL-CANCIO.jpg'
import MANUELJESUSIMG from '../assets/images/MANUEL-JESUS.jpg'
import PEDROPIETROIMG from '../assets/images/PEDRO-PRIETO.jpg'
import PILARESQUINASIMG from '../assets/images/PILAR-ESQUINAS.jpg'
import TERESAFORCADESIMG from '../assets/images/TERESA-FORCADES.jpg'

class Protagonistas extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LosProtagonistas">Los protagonistas (A-Z)</div>
                <br/>
                <p id="carl"><strong>En este proyecto hemos empezado por casa. Hemos rodado ya con una parte importante de los protagonistas españoles. Os los presentamos:</strong></p>
                <br/>
                 <div className="container">
                
                <img src={ANTONIOIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/antoniohuertasthebluedotmovie:e?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/antoniohuertasthebluedotmovie:e"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/antoniohuertasthebluedotmovie:e&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
               
                <br/>
                <p id="carl"><strong>ANTONIO HUERTAS:</strong> Ingeniero ICAI y Gerente de Ingeniería y Proyectos con cuarenta años de experiencia Internacional. Firmante del protocolo de <a href="https://clintel.org/"> <u>CLINTEL</u></a> en 2019.</p>
                
                <br/>
                <br/>
                <div className="container">
               
                <img src={BENITOMUROSIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/benitomurosthebluedotmovie:c?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/benitomurosthebluedotmovie:c"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/benitomurosthebluedotmovie:c&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>BENITO MUROS:</strong> Inventor, empresario y piloto de vuelo. Desarrolló la primera bombilla con tecnología LED sin obsolescencia programada que puede durar más de 80 años.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={CARLOSASTIZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/carlosastizthebluedotmovie:2?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/carlosastizthebluedotmovie:2"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/carlosastizthebluedotmovie:2&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>CARLOS ASTIZ:</strong> Periodista, doctor en Ciencias de la Información y profesor universitario. Su último libro “Bill Gates Reset”, analiza la vida del magnate y fundador de Microsoft.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={CRISTINAMJIMENEZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/cristinamartinjimenezthebluedot:4?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/cristinamartinjimenezthebluedot:4"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/cristinamartinjimenezthebluedot:4=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>CRISTINA MARTÍN JIMÉNEZ: </strong>Doctora en Ciencias de la Comunicación - Periodismo y escritora bestseller especializada en el Club Bilderberg. En su último libro &quot;Los dueños del planeta&quot; analiza la vida de los llamados &quot;filántropos&quot;.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={FERNANDO1IMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/fernandomironesthebluedotmovie:0?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/fernandomironesthebluedotmovie:0"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/fernandomironesthebluedotmovie:0&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>FERNANDO LÓPEZ-MIRONES: </strong>Biólogo y Zoólogo.  Ha escrito y/o dirigido más de 130 películas documentales internacionales, entre ellas de National Geographic y de la BBC. Autor de “Yo, Negacionista”.</p>
                <br/>
                <br/>
                <div className="container">
                
                </div>
                <br/>
                <div className="container">
                <img src={FRANCESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/francescfeliuthebluedotmovie:9?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/francescfeliuthebluedotmovie:9"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/francescfeliuthebluedotmovie:9&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>FRANCESC FELIU:</strong> Abogado. Experto en responsabilidad civil sanitaria y derecho de la salud. Ha llevado todo tipo de procedimientos relacionados con la salud: negligencias médicas y reclamaciones por efectos adversos de medicamentos y vacunas.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={JORDIPIGEMIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jordipigemthebluedotmovie:2?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jordipigemthebluedotmovie:2"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jordipigemthebluedotmovie:2&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JORDI PIGEM:</strong> Doctor en filosofía y escritor. Fue coordinador de la revista de ecología Integral. Entre sus libros destacan &quot;Técnica y totalitarismo&quot; , &quot;Pandemia y posverdad&quot;.</p>
                <br/>
                <br/>
                <div className="container">
                
                <img src={JRFERRNADISIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jrferrandisthebluedotmovie:2?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jrferrandisthebluedotmovie:2"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jrferrandisthebluedotmovie:2&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JOSÉ-RAMON FERRANDIS: </strong>Politólogo, Técnico Comercial y Economista del Estado. Ha sido Consejero Económico y Comercial en las Embajadas de España en Moscú (URSS), Washington DC y Riad. Especialista en Análisis de Mercados Internacionales, así como en materias medioambientales y energéticas.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={JUANDECASTROIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jadecastrothebluedotmovie:4?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jadecastrothebluedotmovie:4"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jadecastrothebluedotmovie:4&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JUAN ANTONIO DE CASTRO: </strong>Doctor en Ciencias Económicas. Trabajó como funcionario permanente de las Naciones Unidas. En 1994 fue nombrado por el Consejo de Seguridad de NU, como economista de la Misión Especial de Paz para Afganistán.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={LORENZORAMIREZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/lorenzoramirezthebluedotmovie:4?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/lorenzoramirezthebluedotmovie:4"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/lorenzoramirezthebluedotmovie:4&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>LORENZO RAMÍREZ: </strong>Periodista y escritor. Ha trabajado en varios medios financieros.  (Expansión, Forbes). En la actualidad dirige un programa de televisión semanal denominado &quot;El Gran Reseteo&quot; en el que analiza las claves del proyecto tecnocrático global.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={LOURDESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/lourdesrellosothebluedotmovie:8?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/lourdesrellosothebluedotmovie:8"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/lourdesrellosothebluedotmovie:8&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>LOURDES RELLOSO: </strong>Psicóloga clínica y sexóloga. Experta en Comunicación Estratégica y prevencion, detección y peritaje de abuso sexual infantil.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={MANGELIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/angelfdezcanciothebluedotmovie:7?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/angelfdezcanciothebluedotmovie:7"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/angelfdezcanciothebluedotmovie:7&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>ÁNGEL FERNÁNDEZ CANCIO:</strong> Doctor en Física y experto en dendroclimatología (el estudio del clima a través de los anillos de los árboles). Trabajó como asesor en Epidemiología Matemática y Zoonosis en materia medioambiental. Firmante de <a href="https://clintel.org/"> <u>CLINTEL</u></a>.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={MANUELJESUSIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/mjrodriguezthebluedotmovie:f?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/mjrodriguezthebluedotmovie:f"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/mjrodriguezthebluedotmovie:f&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>MANUEL JESÚS RODRÍGUEZ: </strong>Médico con diversas especialidades. Trabajó como forense en la Administración de Justicia Española, médico de Urgencias y doctorado en Emergencias Vitales. Actualmente realiza autopsias para peritajes privados.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={PEDROPIETROIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/pedroprietothebluedotmovie:7?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/pedroprietothebluedotmovie:7"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/pedroprietothebluedotmovie:7&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>PEDRO PRIETO: </strong>Ingeniero técnico de telecomunicación. Consultor en el desarrollo, construcción y operación de numerosos proyectos solares fotovoltaicos y como director de desarrollo de Energías Renovables.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={PILARESQUINASIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/pilaresquinas:51?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/pilaresquinas:51"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/pilaresquinas:51&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>PILAR ESQUINAS: </strong>Diplomada en Turismo y Abogada experta en Derecho de Aguas. Activista y asesora de Ayuntamientos, plataformas ciudadanas y asociaciones, sobre los caminos legales que deben tomar para evitar que se privatice el agua en manos de unos pocos fondos de inversión.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={TERESAFORCADESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/teresaforcadesthebluedotmovie:2?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click aqui para ver la entrevista</a></u>
                <br/>
                
                <div id="carl"><h6>Puedes compartir ese video a través de :</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/teresaforcadesthebluedotmovie:2"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/teresaforcadesthebluedotmovie:2&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>TERESA FORCADES: </strong>Doctora en Medicina, teóloga por la universidad de Harvard y monja española de la Orden de San Benito. Mundialmente conocida por su fuerte oposición a la industria farmacéutica.</p>
                
                
                

            </div>
        </div>
        );

    }
}

export default Protagonistas;













