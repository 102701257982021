import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

import { TicketBox11 } from "./paymentBox/DonateBoxEng";
import { TicketBox12 } from "./paymentBox/DonateBoxEng";
import { TicketBox13 } from "./paymentBox/DonateBoxEng";
import { TicketBox14 } from "./paymentBox/DonateBoxEng";
import { TicketBox15 } from "./paymentBox/DonateBoxEng";

// Function to handle font size change by screen size
const handleFontSizeChange = (theme) => ({
	[theme.breakpoints.down("sm")]: {
	  fontSize: "16px",
	  fontWeight: "500",
	},
	[theme.breakpoints.up("md")]: {
	  fontSize: "19px", 
	  fontWeight: "500",
	},
});


const MuiCines = () => {
	// Info from DonateBox
	const [expanded, setExpanded] = useState(false);

	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};

	const theme = useTheme();

	return (
		<div className="wrapper">
			<Accordion
				expanded={expanded === "panel1"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel1")}
			>
				<AccordionSummary
					id="panel1-header"
					aria-controls="panel1-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Cinemas</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<hr/>
					<div className="accordionBorder">
						<TicketBox11 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox12 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
					  	<TicketBox13 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox14 /> 
					</div>
                    <hr/>

                    <div className="accordionBorder">
						<TicketBox15 /> 
					</div>
				</AccordionDetails>
			</Accordion>

		</div>
	);
};

export default MuiCines;
