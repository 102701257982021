import React, { Component } from "react";
import MuiAccordion from "./MuiAccordionEng";
import MuiEntrada from "./MuiEntradasEng";
import { TotalRecaudado } from "./TotalRecaudadoEng";
import MuiCines from "./MuiCinesEng";
import MuiGroups from "./MuiGroupsEng";

class Progress_bar extends Component {
	render() {
		const { showMuiAccordion, setShowMuiAccordion } = this.props;
		const { showMuiEntradas, setShowMuiEntradas } = this.props;
		const { showMuiCines, setShowMuiCines } = this.props;
		const { showMuiGroup, setShowMuiGroup } = this.props;


		return (
			<div className="col-xs-12">
				<TotalRecaudado />

				
				<div>
					{showMuiAccordion && showMuiEntradas && showMuiCines && showMuiGroup ? (
						<React.Fragment>
							<MuiAccordion setShowMuiAccordion={setShowMuiAccordion} />
							<MuiEntrada setShowMuiEntradas={setShowMuiEntradas} />
							<MuiCines setShowMuiCines={setShowMuiCines} />
							<MuiGroups setShowMuiGroup={setShowMuiGroup} />
						</React.Fragment>
					) : (
						<button
							id="btn-colabora"
							className="button button2 pt-5"
							onClick={() => {
								setShowMuiAccordion(true);
								setShowMuiEntradas(true);
								setShowMuiCines(true);
								setShowMuiGroup(true);
							}}
						>
							<i className="fa-solid fa-hand-holding-heart"></i>
							Collaborate
						</button>
					)}
				</div>
			</div>
		);
	}
}
export default Progress_bar;
