import React, { Component } from 'react'

import Agend from '../assets/images/AGENDA.jpg'
import Objetivos from '../assets/images/POSTERTBDWEB.jpg'



class AgendaEng extends Component {
    render() {
        return (
            <div id="Agenda">
                <div className="col-xs-12">
                    <br />
                    <div className="w3-container w3-gray" id="LaAgenda">The Agenda</div>
                    <br />
                    <p id="carl"><strong>Transhumanist philosophers consider that the modern subject it is exterminating the planet. For them this may happen around the year 2030.</strong> Beyond this decade, the New World Order would be formed by a small elite that would enjoy all physical and material goods.<strong> This dystopian scenario is already beginning to take shape and the globalist elites have formalized it in an agenda, precisely called Agenda 2030.</strong>
                    </p>
                    <img src={Agend} alt="Nature" className="responsive" id="carl" />
                    <h6>2030 AGENDA GOALS</h6>
                    <br />
                    <p id="carl"><strong>Under beautiful slogans such as </strong>"The end of poverty" or "0 Hunger", and adorned with colorful shades, <strong> it is hidden an agenda which aims to change every aspect of our free societies</strong> in favour of a few private conglomerates.<strong>These are the 17 points of The Agenda translated into reality.</strong>
                    </p>
                    <br />
                    <img src={Objetivos} alt="Nature" className="responsive" id="grandeimg"/>
                    <br/>
                    <a href={Objetivos} download>
                        Download
                    </a>
                    <br /> <br />
                    <p id="carl"><strong>This New World Order has several names: GREAT RESET, AGENDA 2030, ECOLOGISM, GLOBALISM, TRANSHUMANISM...</strong> but they all respond to the same program aimed at implementing a new model of society under the control of the globalist elites.</p>

                </div>
            </div>
        );

    }
}

export default AgendaEng;