import React, { Component } from 'react'
import "../assets/css/App.css";

import yumboimg from '../assets/images/TBD-MEDIA-KIT-CABECERA.jpg'



class Comoayudartdb extends Component {
    // Función para descargar un archivo cuando se hace clic en el botón
    downloadFile = (url, fileName) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

    }
    render() {

        return (


            <div id="comoayudar">
                <nav className="navbar navbar-inverse">
                    <div className="container-fluid">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#myNavbar">
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <a className="navbar-brand" href="#">The Blue Dot</a>
                        </div>
                        <div className="collapse navbar-collapse" id="myNavbar">
                            <ul className="nav navbar-nav">
                                <li ><a href="/">INICIO</a></li>
                                <li className="active"><a href="/como-ayudar">COMO AYUDAR</a></li>



                            </ul>

                        </div>
                    </div>
                </nav>

                <div className="jumbotron" id="jumboayuda">

                    <img src={yumboimg} alt="Nature" className="responsive" id="banerayuda" />
                    <br /><br />
                    <div id="encabezado">
                        <h5><strong>CÓMO AYUDAR A DIFUNDIR THE BLUE DOT MOVIE</strong></h5>
                    </div>
                    <div id="texto-encabezado">
                        <p><strong>Existen diferentes maneras de colaborar en el proyecto. La primera es compartiendo el
                            tráiler y las piezas de los protagonistas.</strong> La censura que estamos viviendo en estos

                            tiempos es brutal, Youtube, Vimeo, Instagram y ahora WhatsApp censuran este
                            contenido. Puedes comprobarlo tú mismo intentando compartir alguna de estas piezas
                            en WhatsApp. Han implementado un algoritmo que impide que esta información se
                            comparta y la gente pueda enterarse de lo que está pasando. ¿Si antes censuraban
                            porque había un virus muy peligroso, por qué siguen censurando ahora que no lo hay?
                            La respuesta es sencilla, esto nunca ha ido sobre un virus.</p>
                    </div>
                    <div id="descargacomparte">
                        <h5><strong>DESCARGA Y COMPARTE EL TRÁILER DE THE BLUEDOT MOVIE</strong></h5>
                    </div>
                    <div id="texto-encabezado">
                        <p id="ptextodescarga"><strong>Puedes compartir el tráiler en tus redes sociales,</strong> seguramente será baneado, pero
                            mientras lo censuran, mucha más gente será informada de la existencia de este
                            proyecto.</p>
                        <br />


                        <h5>TRAILER CROWDFUNDING THE BLUE DOT MOVIE (ALTA CALIDAD 1,2GB)</h5>
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1aizD5VEv_19gNDkeI7a1D8c66pWu4GL4&export=download',

                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ESPAÑOL)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1xRihQKW1D4uro5RpFRuQzY-7Id-4YkBP&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ENGLISH)</button>


                        <br />
                        <br /> <br />
                        <h5>TRAILER CROWDFUNDING THE BLUE DOT MOVIE (SOCIAL MEDIA. 159MB)</h5>
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1aizD5VEv_19gNDkeI7a1D8c66pWu4GL4&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ESPAÑOL)</button>


                        <br />
                        <br />




                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1xmkVCsO0OywnENHUEdb-7PoRQ1uJsalq&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ENGLISH)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1JSBom3Yk1Q756ySwrDsqObhOuJW8LHpD&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ARABIC)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=13AJhFpsP8Vd566XhViqhbrA4Di03pS1D&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (CHINESE)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1Jpv0vktb1K-JYin-ayOQrFfqs0aFRJw1&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (DANISH)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=13uDr5n97cvnkipfnvvu3GnkxcGkKjfII&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (DUTCH)</button>


                        <br />
                        <br />



                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1MsTLdAWbwnNL1oXXhjHJiwLzFVRGuJvL&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (FRENCH)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1k_-FX_1WTLQWsvOESH8TVFHiZreg9cff&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (GERMAN)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1017CLJnREaLN1lqB0f_9xolIyOmnEdMx&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (GREEK)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1Wj4AbTIF1EbKQny3fVZ8rBVjjSGUOQr_&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (ITALIANO)</button>

                        <br />
                        <br />



                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=16Ke0JLEG1zpodb9nwfIcCN9SFQwS6tOP&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (JAPANESE)</button>

                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1oMI9SXTrk17UYVnHq8xpfiN4vVQJUr_w&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }



                        >Descargar trailer (POLISH)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1G4tNUtMw6Zl__qzooNdaoN4KhIrf8aR-&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (RUMANO)</button>


                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1McHot63XH88zK2-F2_BkSdpDDc7UciKH&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (PORTUGUES)</button>

                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1VafK7vBvK27xQq1GCtjR2UTSRDlnctKV&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (Russian)</button>

                        <br />
                        <br />

                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=196_W-bu90qFih0xawgKtwHXqbIdEXlBG&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >Descargar trailer (UKRANIAN)</button>


                        <br /><br />
                        <div id="descargacomparte">
                            <h5><strong>DESCARGA EL CARTEL PARA IMPRIMIR</strong></h5>
                        </div>
                        <div id="texto-encabezado">
                            <p>Con la censura que a día de hoy existe en los medios digitales, cada vez es más difícil
                                poder compartir esta información con otras personas. Por eso vamos a volver a vestir
                                las calles de toda España con este mensaje.<strong> Puedes hacer una aportación para continuar con la pegada masiva que se está llevando a cabo en
                                    todas las ciudades de España, o bien puedes descargar el cartel promocional del
                                    documental, imprimirlo y colocarlo en la entrada de tu establecimiento,</strong> asociación,
                                tienda, peluquería, gimnasio, en las escaleras de tu casa, en la cafetería de tu oficina…
                                Este mensaje tiene que llegar a todo el mundo.</p>
                        </div>



                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=1y5Kh8Sg5cWbP1jJy99yPhFfNWht1tIl4&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >CARTEL TBD POSTER (700x100).pdf</button>


                        <br /><br />
                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/file/d/1l7mcmZgNvzTP1I8tbKD__OuRYXN2PcnA/view?usp=drive_link',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >CARTEL TBD FLYER (10,5X14,8CM).pdf</button>
                        <br /><br />
                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=12_hoIMULIqvkQuEeaPqjsYkB7LbY5fVc&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >CARTEL TBD A3 CON 8 FLYERS (29,7X42CM).pdf</button>
                        <br /><br />
                        <button
                            onClick={() =>
                                this.downloadFile(
                                    'https://drive.google.com/u/3/uc?id=16UEf4NcWZXEATQJ4WBbU9H7MTJvVynfc&export=download',
                                    'Trailer_ESPANOL.mp4'
                                )
                            }

                        >CARTEL TBD WEB.jpg</button>
                    </div>
                    <br />
                    <div id="texto-encabezado">
                        <p><strong>Hemos creado también un documento descargable que incluye stickers en diferentes
                            tamaños con el código QR</strong> directo a la web del documental. Marketing de guerrilla
                            para estos tiempos modernos.</p>
                    </div>
                    <br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=17OQMIf3-VZzl45wOjG-hIyQNtcZC0D6S&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >STICKERS A3</button>
                    <br /><br />
                    <div id="descargacomparte">
                        <h5><strong>DESCARGA Y COMPARTE EL MATERIAL OFICIAL PARA REDES SOCIALES</strong></h5>
                    </div>
                    <div id="texto-encabezado">
                        <p>
                            <strong>Puedes colaborar en la difusión de este mensaje compartiendo en tu Instagram y
                                Facebook los stories</strong> y publicaciones que hemos preparado para el Feed.
                        </p>
                    </div>
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1rcMLPT7wlmW-4Z2oWBPWatkHovKea85i/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1080x1920 STORIES TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1px4JWq2r_X_VVjcfkjcShFehqytwO_y3/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1080x1080 FEED TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1cKxKAQzwbiv1ys1Sm9hfczal3Ks65IZy/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >640x360 FB HEADER MOBILE TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1g59nNPLSQcOpvwdGxT-dLZs8C9Hbw9i2/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1200x628 FB SHARED LINK TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1g59nNPLSQcOpvwdGxT-dLZs8C9Hbw9i2/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1200x628 FB SHARED LINK TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1-8kTS19qye0ib3YE5wDmZ2VSGLN8MB6k/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1200x630 FB SHARED IMG TBD.jpg</button>
                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/file/d/1ouQrVaDx61vZwN_gZGV8DJNKm_L11XAB/view',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >1920x1005 FB EVENT IMAGE TBD.jpg</button>

                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=1uGaLIi_zXfBViiCZJE6RClKN76FAAzKP&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >REEL TBD MOVIE AGENDA (ESP)</button>

                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=1ul_9b9f2d_T1xiv6QHObEMbJtzz76VJ_&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >REEL TBD MOVIE AGENDA (ENG)</button>


                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=1Q5jI8Ks74hb_U5fxsFYtIwIU6sQxm64I&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >REEL TBD MOVIE EL PLAN (ESP)</button>

                    <br /><br />
                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=1XCsHIb6Hb0-zdjx9fhqsYIvBbzYtQlvD&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >REEL TBD MOVIE THE PLAN (ENG)</button>

                    <br /><br />

                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=1OPHerEWSFWz34xVB8Fi_CSHheidhsdX_&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >STORIES CINEMAS (ESP)</button>

                    <br /><br />

                    <button
                        onClick={() =>
                            this.downloadFile(
                                'https://drive.google.com/u/3/uc?id=14rSM9OibmV77miXrGyKtpcJxBMxX96Et&export=download',
                                'Trailer_ESPANOL.mp4'
                            )
                        }

                    >STORIES CINEMAS (ENG)</button>

                    <br /><br />

                    <br /><br />
                    <div id="descargacomparte">
                        <h5><strong>SÍGUENOS EN REDES SOCIALES</strong></h5>
                    </div>
                    <div id="texto-encabezado">
                        <div id="Listaredes">
                            <ul>
                                <li id="listaredes"><a href="https://t.me/thebigresetdocumental"><u>TELEGRAM</u></a></li>
                                <li id="listaredes"><a href="https://www.instagram.com/tbrmovie/?igshid=MzRlODBiNWFlZA%3D%3D"><u>INSTRAGRAM</u></a></li>
                                <li id="listaredes"><a href="https://www.tiktok.com/@bigresetmovie?_t=8ertJazBwCP&amp;_r=1"><u>TIK TOK</u></a></li>

                            </ul>
                        </div>
                    </div>

                    <br /><br />




                </div>


            </div>


        );

    }
}

export default Comoayudartdb;