import React, { Component } from 'react'

import Transhumanismoimg from '../assets/images/TRANSHUMANISMO.jpg'



class TranshumanismoEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Eltranshumanismo">The transhumanism</div>
                
                <br/>
                <img src={Transhumanismoimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">The Agendas 2030 and Avatar 2045 are projects that aim at a transformation not only of economic systems, political, and cultural systems but also religious systems, nature, and the modification of human beings themselves at the genomic level, which makes transhumanism the axis of this Agenda.<strong> This process, known as Fourth Industrial Revolution, is the forerunner of an era that aims to replace humans with machines.</strong></p>

            </div>
        </div>
        );

    }
}

export default TranshumanismoEng;