import React, { Component } from 'react'

import LASRECOMPENSASIMG from '../assets/images/16.LASRECOMPENSAS.jpg'



class LasrecompensasEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LasRecompensas">The rewards</div>
                
                <br/>
                <p id="carl"><strong>These rewards have dual value. On one hand, they help to continue spreading the message, and on the other hand, you are contributing to financing the project.</strong></p>
                <br />
                <img src={LASRECOMPENSASIMG} alt="Nature" className="responsive" />
                <br /> <br/>
               

            </div>
        </div>
        );

    }
}

export default LasrecompensasEng;