import React, { Component } from 'react'

import LASRECOMPENSASIMG from '../assets/images/16.LASRECOMPENSAS.jpg'



class Lasrecompensas extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LasRecompensas">Las recompensas</div>
                
                <br/>
                <p id="carl"><strong>Estas recompensas tienen doble valor. Por un lado ayudan a seguir con la difusión del mensaje y por otro estás colaborando a financiar el proyecto.</strong></p>
                <br />
                <img src={LASRECOMPENSASIMG} alt="Nature" className="responsive" />
                <br /> <br/>
               

            </div>
        </div>
        );

    }
}

export default Lasrecompensas;