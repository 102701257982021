import React, { Component } from 'react'


import TELEGRAMIMG from '../assets/images/LOGOTELEGRAMBLANCO.png'
import INSTAGRAMIMG from '../assets/images/LOGOINSTAGRAMBLANCO.png'
import TIKTOKIMG from '../assets/images/TiktokBLANCO.png'
import BTNCOMPARTIRIMG from '../assets/images/BOTONCOMPARTIRPAGINA.png'


class Footer extends Component {
    render() {
        return (
            <div id="Footer">
                <div className="col-xs-12">
                   
                        <div className="row">
                            <div className="col-xs-12 col-md-3" id="terminos">
                            <p id="txtcondiciones">WWWW.THEBLUEDOTMOVIE.COM</p>
                                <p id="txtcondiciones">TERMS AND CONDITIONS</p>
                                <p id="txtcondiciones">POLICIES AND COOKIES</p>

                            </div>
                            <div className="col-xs-12 col-md-6" id="redes">
                            <a href='https://t.me/thebluedotmovie'><img src={TELEGRAMIMG} alt="Nature" id="telegramimg" /></a><img src={INSTAGRAMIMG} alt="Nature" id="instagramimg" /><a href='https://www.tiktok.com/@thebluedotmovie?_t=8g3osqxLBGC&_r=1'><img src={TIKTOKIMG} alt="Nature" id="instagramimg" /></a>
                                <br/>
                                <div id='carl'>
                                <h6>Banned @thebluedotmovie</h6>
                                </div>
                                
                            </div>
                            <div className="col-xs-12 col-md-3" id="comparte">
                                <p id="compartepagina">SHARE THIS WEB</p>
                                <a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20aqui%20https://thebluedotmovie.com/"><img src={BTNCOMPARTIRIMG} alt="Nature" id="logocomparte" /></a>
                                
                            </div>
                           
                        </div>
                    

                </div>
            </div>
        );

    }
}

export default Footer;