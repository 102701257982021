import React, { Component } from 'react'
import carl from '../assets/images/sagan.jpg'
import paledot from '../assets/images/pale-dot.jpg'
import desafios from '../assets/images/DESAFIOSHUMANIDAD.jpg'


class SabermasEng extends Component {
    render() {
        return (
            <div id="saber">
                <div className="col-xs-12">
                    <br />
                    <div className="w3-container w3-gray" id="Sabermas">know more about the documentary</div>
                    <img src={carl} alt="Nature" className="responsive" id="carl" />
                    <h6>CARL SAGAN AND THE VOYAGER 1</h6>
                    <br />
                    <p id="carl"><strong>In 1990 astronomer Carl Sagan decided that it would be interesting for the Voyager 1 spacecraft,</strong> after completing its mission to Saturn,<strong> to turn around and take a picture of Earth. Seen through that enormous distance, our planet is inconsequential,</strong> as Carl Sagan said: "a speck of dust suspended in a sunbeam." But it is <strong>a blue speck; hence the photograph is famous name. "A pale blue dot."</strong>
                    </p>
                    <br />
                    <img src={paledot} alt="Nature" className="responsive" id="carl" />
                    <h6>PHOTOGRAPHY "A PALE BLUE POINT"</h6>
                    <br />
                    <p id="carl"><strong>"This here is our home, that is us; every hero and coward, every mother and father, every creator and destroyer of civilizations, lived here, on a speck of blue dust, suspended in a ray of sunshine. In all this vast darkness, in this vastness, there is no hint that help will come from elsewhere... to save us from ourselves." (Carl Sagan. 1934-1996).</strong>
                    </p>
                    <br />
                    <img src={desafios} alt="Nature" className="responsive" id="paledot" />
                    <br /> <br/ >
                    <p id="carl"><strong>The Blue Dot will address all the challenges that threaten the future of humanity. Pandemics, wars, climate change,</strong> economic destruction, implementation of a universal basic income, digitization of the planet and personal control with microchips <strong>are just the stepping stones to achieve the goals: absolute control of the planets resources as well as the human mind and body.</strong>
                    </p>
                </div>
            </div>
        );
    }
}
export default SabermasEng;