import React, { Component } from 'react'

import Futurohumanidadimg from '../assets/images/ELFUTURODELAHUMANDAD.jpg'



class Futurohumanidad extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Elfuturodelahumanidad">El futuro de la humanidad</div>
                
                <br/>
                <img src={Futurohumanidadimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">Estos son, a grandes rasgos, los planes que estas élites tienen diseñados para nosotros y de los que tratará el documental. Pero eso no significa que todo les vaya a salir como lo tienen planeado.<strong> A lo largo y ancho del planeta existen voces valientes que se salen del discurso imperante, programado desde los medios de comunicación de masas. Estos serán algunos de los protagonistas de este proyecto.</strong></p>

            </div>
        </div>
        );

    }
}

export default Futurohumanidad;