import "@stripe/stripe-js";
import React, { useState } from "react";
import "./assets/css/App.css";
import "./assets/css/accordion.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route, Link } from "react-router-dom";
import Router2 from "./Router2";


// COMPONENTES

import Header from "./components/Header";
import Progress_bar from "./components/Progress_bar";
import Trailer from "./components/Trailer";
import Sabermas from "./components/Sabermas";
import Agenda from "./components/Agenda";
import Faqs from "./components/Faqs";
import Falsapandemia from "./components/Falsapandemia";
import Massmedia from "./components/massmedia";
import Disidente from "./components/Disidente";
import Calentamientoglobal from "./components/Calentamientoglobal";
import Dictaduraecologica from "./components/Dictaduraecologica";
import Transhumanismo from "./components/Transhumanismo";
import Futurohumanidad from "./components/Futurohumanidad";
import Protagonistas from "./components/Protagonistas";
import Elobjetivo from "./components/Elobjetivo";
import Lasrecompensas from "./components/Lasrecompensas";
import Fondos from "./components/Fondos";
import Footer from "./components/Footer";
import Comoayudartdb from "./components/Comoayudartbd";
import Principal from "./components/Principal";
import PrincipalENG from "./components/PrincipalENG";
import ComoayudartdbEng from "./components/ComoayudartbdEng";

function App() {
	{/*const [showMuiAccordion, setShowMuiAccordion] = useState(false);
	const [showMuiEntradas, setShowMuiEntradas] = useState(false);
	const [showMuiCines, setShowMuiCines] = useState(false);
	const [showMuiGroup, setShowMuiGroup] = useState(false);
	*/}

	return (

		<Router>
			<div className="App">
				{/*<Header />
				<Trailer />
				<Progress_bar
					showMuiAccordion={showMuiAccordion}
					setShowMuiAccordion={setShowMuiAccordion}
					showMuiEntradas={showMuiEntradas}
					setShowMuiEntradas={setShowMuiEntradas}
					showMuiCines={showMuiCines}
					setShowMuiCines={setShowMuiCines}
					showMuiGroup={showMuiGroup}
					setShowMuiGroup={setShowMuiGroup}
				/>
			*/}
				<Routes>
					<Route exact path="/" element={<Principal />} />
					<Route exact path="/como-ayudar" element={<Comoayudartdb />} />
					<Route exact path="/ENG/Principal" element={<PrincipalENG />} />
					<Route exact path="/ENG/help" element={<ComoayudartdbEng />} />
				</Routes>
				{/*<Faqs />
				<Sabermas />
				<Agenda />
				<Falsapandemia />
				<Massmedia />
				<Disidente />
				<Calentamientoglobal />
				<Dictaduraecologica />
				<Transhumanismo />
				<Futurohumanidad />
				<Protagonistas />
				<Elobjetivo />
				<Lasrecompensas />
				<Fondos />
				<Footer />
				*/}
				<header className="App-header"></header>
			</div>
		</Router>

	);
}

export default App;
