import React, { Component } from 'react'
import carl from '../assets/images/sagan.jpg'
import paledot from '../assets/images/pale-dot.jpg'
import desafios from '../assets/images/DESAFIOSHUMANIDAD.jpg'


class Sabermas extends Component {
    render() {
        return (
            <div id="saber">
                <div className="col-xs-12">
                    <br />
                    <div className="w3-container w3-gray" id="Sabermas">Saber más del documental</div>
                    <img src={carl} alt="Nature" className="responsive" id="carl" />
                    <h6>CARL SAGAN Y LA VOYAGER 1</h6>
                    <br />
                    <p id="carl"><strong>En 1990 el astrónomo Carl Sagan decidió que sería interesante que la nave espacial Voyager 1</strong>, después de completar su misión en Saturno,<strong> diese la vuelta y tomase una foto de la Tierra. Vista a través de esa enorme distancia, nuestro planeta es intrascendente</strong>, cómo dijo Carl Sagan “una mota de polvo suspendida en un rayo de sol”. Pero es <strong>una mota azul; de ahí el famoso nombre de la fotografía. “Un punto azul pálido”.</strong>
                    </p>
                    <br />
                    <img src={paledot} alt="Nature" className="responsive" id="carl" />
                    <h6>FOTOGRAFIA "UN PUNTO AZUL PALIDO"</h6>
                    <br />
                    <p id="carl"><strong>“Esto que está aquí es nuestro hogar, eso somos nosotros; cada héroe y cobarde, cada madre y padre, cada creador y destructor de civilizaciones, vivió aquí, en una mota de polvo azul, suspendida en un rayo de sol. En toda esta inmensa oscuridad, en esta vastedad, no hay ningún indicio de que la ayuda vendrá de otra parte… para salvarnos de nosotros mismos”. (Carl Sagan. 1934-1996).</strong>
                    </p>
                    <br />
                    <img src={desafios} alt="Nature" className="responsive" id="paledot" />
                    <br /> <br/ >
                    <p id="carl"><strong>The Blue Dot, tratará de abordar todos los desafíos que amenazan el futuro de la humanidad. Pandemias, guerras, cambios climáticos,</strong> destrucción económica, implantación de una renta básica universal, digitalización del planeta y control personal con microchips <strong>sólo son los peldaños para lograr los objetivos: el control absoluto de los recursos del planeta además de la mente y el cuerpo del ser humano.</strong>
                    </p>
                </div>
            </div>
        );
    }
}
export default Sabermas;