import React, { Component } from 'react'

import Falsapandemiaimg from '../assets/images/Falsapandemia.jpg'



class FalsapandemiaEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LaFalsapandemia">The false pandemic</div>
                
                <br/>
                <img src={Falsapandemiaimg} alt="Nature" className="responsive" />
                <h6>BILL GATES AND HIS BOOK “HOW TO PREVENT THE NEXT PANDEMIC”</h6>
                 <br/>
                <p id="carl"><strong>Within the framework of this globalist plan, the term psychopandemic was presented as an "accelerating agent."</strong> These new rulers, lacking any democratic legitimacy, now claim the right to govern the people in the name of universal health and security, based on alleged scientific evidence.<strong> The plan did not go as smoothly as they expected, but they will surely attempt it again.</strong></p>

            </div>
        </div>
        );

    }
}

export default FalsapandemiaEng;