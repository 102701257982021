import React, { Component } from 'react'

import RICHARDLINDIMG from '../assets/images/1.RICHARD-LINDZEN.jpg'
import PATRICKALBERTIMG from '../assets/images/2.PATRICKALBERTMOORE.jpg'
import PIERSCORBYNIMG from '../assets/images/3.PIERS-CORBYN.jpg'
import CATHERINEZIMG from '../assets/images/4.CATHERINE-AUSTIN-FITTS.jpg'
import CALINIMG from '../assets/images/5.CALIN-GEORGESCU.jpg'
import MIKEYEAIMG from '../assets/images/6.MIKEYEADON.jpg'
import CHRISTINEANDIMG from '../assets/images/7.CHISTINE-ANDERSON.jpg'
import ATHANASIUSIMG from '../assets/images/8.ATHANASIO.jpg'
import ROBERTIMG from '../assets/images/9.BOB-KENNEDY-JR.jpg'


class ElobjetivoEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="elobjetivo">The objective</div>
                <p id="carl"><strong>But to build a global narrative, we will have to venture outside in search </strong>of more brave voices that are not willing to remain silent while the 1% of the population tries to manipulate us with their colorful and tyrannical slogans.<strong> These will also be the protagonists, most of whom have already been confirmed, for this second documentary.</strong></p>
                <br/>
                <img src={RICHARDLINDIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>RICHARD LINDZEN:</strong> American atmospheric physicist and professor of Meteorology at the Massachusetts Institute of Technology (MIT). He has worked for years on the Intergovernmental Panel on Climate Change (IPCC), questioning its findings and criticizing what he has referred to as "climate alarmism."</p>
                <br/>
                <br/>
                <img src={PATRICKALBERTIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>PATRICK ALBERT MOORE:</strong> Canadian industrial consultant and former activist. He served as the President of Greenpeace Canada until 1986 when he voluntarily resigned, stating that the environmental movement had "abandoned science and logic in favor of emotion and sensationalism."</p>
                <br/>
                <br/>
                <img src={PIERSCORBYNIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>PIERS CORBYN:</strong> English meteorologist, astrophysicist, and businessman. He is a member of the Royal Geographical Society and the founder of WeatherAction. His studies are based on the "solar weather technique," asserting that any temperature increase is due to the sun rather than CO2.</p>
                <br/>
                <br/>
                <img src={CATHERINEZIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CATHERINE AUSTIN FITTS:</strong> American investment banker and former government official. She is an expert in financial and geopolitical mechanisms related to the Great Reset, as well as being highly knowledgeable about the workings of Central Bank Digital Currencies (CBDC).</p>
                <br/>
                <br/>
                <img src={CALINIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CALIN GEORGESCU:</strong> Former Executive Director at the United Nations and former President of the European Research Centre of the Club of Rome. Georgescu describes the process of global infiltration and takeover of the United Nations by oligarchs, particularly Klaus Schwab and the World Economic Forum.</p>
                <br/>
                <br/>
                <img src={MIKEYEAIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>MIKE YEADON:</strong> Notable researcher in allergies and respiratory pathways for 16 years at Pfizer, holding high executive positions within the company. He has become a global icon in the fight against globalist interests.</p>
                <br/>
                <br/>
                <img src={CHRISTINEANDIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>CHRISTINE ANDERSON:</strong> Member of the European Parliament since 2019. A strong advocate for human rights, she has even drawn comparisons between the pandemic situation and Nazi Germany.</p>
                <br/>
                <br/>
                <img src={ATHANASIUSIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>ATHANASIUS SCHNEIDER:</strong> Bishop of the Catholic Church and member of the Canons Regular of the Holy Cross. Critical of certain policies implemented by Pope Francis, Athanasius has publicly expressed his opposition to the health dictatorship that institutions such as the WHO are attempting to implement.</p>
                <br/>
                <br/>
                <img src={ROBERTIMG} alt="Nature" className="responsive" />
                <p id="carl"><strong>ROBERT KENNEDY JR:</strong> Nephew of US President John Fitzgerald Kennedy and current candidate for the presidency from the Democratic Party. He has claimed that the global mRNA vaccination campaign is a military project initiated by the Pentagon in 2012.</p>
                <br/>
                
                

            </div>
        </div>
        );

    }
}

export default ElobjetivoEng;


