import React, { Component } from 'react'

import MODELOSEMPLEOIMG from '../assets/images/17.EMPLEODEFONDOS.jpg'
import CINESIMG from '../assets/images/18.ESTRENO-EN-CINES.jpg'
import WIMG from '../assets/images/19.W.jpg'



class FondosEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LosFondos">What will we do with the funds?</div>
                
                <br/>
                <p id="carl"><strong>Like any audiovisual production, it will involve expenses such as travel, rental of audiovisual equipment, suitable recording spaces </strong>for each location,<strong> in addition to the subsequent post-production process</strong>, animations, color grading, sound design, multilingual dubbing, and the final step: adaptation to cinema format.</p>
                <br />
                <img src={MODELOSEMPLEOIMG} alt="Nature" className="responsive" />
                <br />
                <br/>
                <p id="carl">Once the filming adventure is completed, and following the approach we took with the previous documentary, "The Big Reset Movie", we will premiere the documentary in cinemas. The objective of all this is to bring the message to as many people as possible.</p>
                <br />
                <img src={CINESIMG} alt="Nature" className="responsive" />
                <br />
                <div className="w3-container w3-gray" id="LosProtagonistas">Who are we?</div>
                <br/>
                <p id="carl"><strong>We are people like you, tired of what TV tells us every day, but we have decided to stop being mere spectators. Censorship has reached unimaginable levels, which is why, for the sake of the project, it is better for you to know us as W.</strong></p>
                <br />
                <img src={WIMG} alt="Nature" className="responsive" />
                <br /> <br />

            </div>
        </div>
        );

    }
}

export default FondosEng;