import React, { Component } from 'react'

import Calentamientoimg from '../assets/images/CALENTAMIENTOGLOBAL.jpg'



class CalentamientoglobalEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Elcalentamientoglobal">Global warming</div>
                
                <br/>
                <img src={Calentamientoimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl"><strong>The narrative of global warming caused by CO2 emissions from human activity has become a dogma that does not allow any debate,</strong> despite numerous physicists, climatologists, Nobel laureates, and top experts worldwide stating otherwise.<strong> Presented as science, it is portrayed as a new religion, the new ecological totalitarianism of the 21st century.</strong></p>

            </div>
        </div>
        );

    }
}

export default CalentamientoglobalEng;