import React, { Component } from 'react'

import ANTONIOIMG from '../assets/images/ANTONIO-HUERTAS.jpg'
import BENITOMUROSIMG from '../assets/images/BENITO-MUROS.jpg'
import CARLOSASTIZIMG from '../assets/images/CARLOS-ASTIZ.jpg'
import CRISTINAMJIMENEZIMG from '../assets/images/CRISTINA-MJIMENEZ.jpg'
import FERNANDO1IMG from '../assets/images/FERNANDO-1.jpg'
import FRANCESIMG from '../assets/images/FRAM-FRANCESC-3.jpg'
import JORDIPIGEMIMG from '../assets/images/JORDI-PIGEM-3.jpg'
import JRFERRNADISIMG from '../assets/images/JR-FERRNADIS-4.jpg'
import JUANDECASTROIMG from '../assets/images/JUAN-A-CASTRO-1.jpg'
import LORENZORAMIREZIMG from '../assets/images/LORENZO-RAMIREZ.jpg'
import LOURDESIMG from '../assets/images/LOURDES-RELLOSO-2.jpg'
import MANGELIMG from '../assets/images/M.ANGEL-CANCIO.jpg'
import MANUELJESUSIMG from '../assets/images/MANUEL-JESUS.jpg'
import PEDROPIETROIMG from '../assets/images/PEDRO-PRIETO.jpg'
import PILARESQUINASIMG from '../assets/images/PILAR-ESQUINAS.jpg'
import TERESAFORCADESIMG from '../assets/images/TERESA-FORCADES.jpg'

class Protagonistas extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LosProtagonistas">Los protagonistas (A-Z)</div>
                <br/>
                <p id="carl"><strong>In this project we have started at home. We have already filmed with an important part of the Spanish protagonists. We present them to you:</strong></p>
                <br/>
                 <div className="container">
                
                <img src={ANTONIOIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/antoniohuertasENG:7?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/antoniohuertasENG:7"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/antoniohuertasENG:7&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
               
                <br/>
                <p id="carl"><strong>ANTONIO HUERTAS:</strong> Engineer with a degree from ICAI and Manager of Engineering and Projects with forty years of international experience. Signatory of the <a href="https://clintel.org/"> <u>CLINTEL</u></a> protocol in 2019.</p>
                
                <br/>
                <br/>
                <div className="container">
               
                <img src={BENITOMUROSIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/benitomurosthebluedotmovieENG:6?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/benitomurosthebluedotmovieENG:6"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/benitomurosthebluedotmovieENG:6&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>BENITO MUROS:</strong> Inventor, businessman and flight pilot. It developed the first light bulb with LED technology without planned obsolescence that can last more than 80 years.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={CARLOSASTIZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/carlosastizthebluedotmovieENG:5?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/carlosastizthebluedotmovieENG:5"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/carlosastizthebluedotmovieENG:5&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>CARLOS ASTIZ:</strong> Journalist, doctor in Information Sciences and university professor. His latest book “Bill Gates Reset” analyzes the life of the magnate and founder of Microsoft.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={CRISTINAMJIMENEZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/cristinamartinjimenezthebluedotmovieENG:7?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/cristinamartinjimenezthebluedotmovieENG:7"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/cristinamartinjimenezthebluedotmovieENG:7=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>CRISTINA MARTÍN JIMÉNEZ: </strong>Doctor in Communication Sciences - Journalism and best-selling writer specialized in the Bilderberg Club. In his latest book "The owners of the planet" he analyzes the life of the so-called "philanthropists."</p>
                <br/>
                <br/>
                <div className="container">
                <img src={FERNANDO1IMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='ttps://odysee.com/$/embed/@thebigreset:1/fernandomironesthebluedotmovieENG:5?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/fernandomironesthebluedotmovieENG:5"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/fernandomironesthebluedotmovieENG:5&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>FERNANDO LÓPEZ-MIRONES: </strong>Biologist and Zoologist. He has written and/or directed more than 130 international documentary films, including for National Geographic and the BBC. Author of “I, Denialist”.</p>
                <br/>
                <br/>
                <div className="container">
                
                </div>
                <br/>
                <div className="container">
                <img src={FRANCESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/francescfeliuthebluedotmovieENG:c?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/francescfeliuthebluedotmovieENG:c"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/francescfeliuthebluedotmovieENG:c&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>FRANCESC FELIU:</strong> Lawyer. Expert in civil health liability and health law. He has handled all types of health-related procedures: medical negligence and claims for adverse effects of medications and vaccines.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={JORDIPIGEMIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jordipigemthebluedotmovieENG:1?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jordipigemthebluedotmovieENG:1"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jordipigemthebluedotmovieENG:1&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JORDI PIGEM:</strong> Doctor in philosophy and writer. He was coordinator of the Integral ecology magazine. His books include "Technique and totalitarianism", "Pandemic and post-truth".</p>
                <br/>
                <br/>
                <div className="container">
                
                <img src={JRFERRNADISIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jrferrandisthebluedotmovieENG:a?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jrferrandisthebluedotmovieENG:a"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jrferrandisthebluedotmovieENG:a&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JOSÉ-RAMON FERRANDIS: </strong>Political Scientist, Commercial Technician and State Economist. He has been Economic and Commercial Counselor at the Spanish Embassies in Moscow (USSR), Washington DC and Riyadh. Specialist in International Market Analysis, as well as in environmental and energy matters.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={JUANDECASTROIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/jadecastrothebluedotmovieENG:d?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/jadecastrothebluedotmovieENG:d"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/jadecastrothebluedotmovieENG:d&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>JUAN ANTONIO DE CASTRO: </strong>Doctor in Economic Sciences. He worked as a permanent official of the United Nations. In 1994 he was appointed by the UN Security Council as an economist for the Special Peace Mission for Afghanistan.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={LORENZORAMIREZIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/lorenzoramirezthebluedotmovieENG:3?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/lorenzoramirezthebluedotmovieENG:3"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/lorenzoramirezthebluedotmovieENG:3&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>LORENZO RAMÍREZ: </strong>ournalist and writer. He has worked in various financial media. (Expansion, Forbes). He currently runs a weekly television program called &quot;The Great Reset&quot; in which he analyzes the keys to the global technocratic project.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={LOURDESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='ttps://odysee.com/$/embed/@thebigreset:1/lourdesrellosothebluedotmovieENG:5?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/lourdesrellosothebluedotmovieENG:5"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/lourdesrellosothebluedotmovieENG:5&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>LOURDES RELLOSO: </strong>Clinical psychologist and sexologist. Expert in Strategic Communication and prevention, detection and assessment of child sexual abuse.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={MANGELIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/angelfdezthebluedotmovieENG:8?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/angelfdezthebluedotmovieENG:8"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/angelfdezthebluedotmovieENG:8&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>ÁNGEL FERNÁNDEZ CANCIO:</strong> Doctor in Physics and expert in dendroclimatology (the study of climate through tree rings). He worked as an advisor in Mathematical Epidemiology and Zoonoses in environmental matters. Signatory of <a href="https://clintel.org/"> <u>CLINTEL</u></a>.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={MANUELJESUSIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/mjrodriguezthebluedotmovie:f?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visita%20la%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/mjrodriguezthebluedotmovie:f"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/mjrodriguezthebluedotmovie:f&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>MANUEL JESÚS RODRÍGUEZ: </strong>Doctor with various specialties. He worked as a forensic examiner in the Spanish Administration of Justice, an emergency doctor and a doctorate in Vital Emergencies. He currently performs autopsies for private examinations.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={PEDROPIETROIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/pedroprietothebluedotmovieENG:0?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/pedroprietothebluedotmovieENG:0"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/pedroprietothebluedotmovieENG:0&text=Visita thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>PEDRO PRIETO: </strong>Telecommunications technical engineer. Consultant in the development, construction and operation of numerous solar photovoltaic projects and as director of development of Renewable Energies.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={PILARESQUINASIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/pilaresquinasthebluedotmovieENG:5?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/pilaresquinasthebluedotmovieENG:5"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/pilaresquinasthebluedotmovieENG:5&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>PILAR ESQUINAS: </strong>Diploma in Tourism and Lawyer expert in Water Law. Activist and advisor to City Councils, citizen platforms and associations, on the legal paths they should take to prevent water from being privatized in the hands of a few investment funds.</p>
                <br/>
                <br/>
                <div className="container">
                <img src={TERESAFORCADESIMG} alt="Nature" className="responsive" id="objetivosimg"/>
                </div>
                <br/>
                <u><a href='https://odysee.com/$/embed/@thebigreset:1/teresaforcadesthebluedotmovieENG:e?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r'>Click here to see the interview</a></u>
                <br/>
                
                <div id="carl"><h6>You can share that video through:</h6><a href="https://api.whatsapp.com/send?text=Visit%20the%20web%20thebluedotmovie.com%20https://odysee.com/@thebigreset:1/teresaforcadesthebluedotmovieENG:e"><i className="fa-brands fa-whatsapp fa-2xl"></i> </a> <a href="tg:msg_url?url=https://odysee.com/@thebigreset:1/teresaforcadesthebluedotmovieENG:e&text=Visit thebluedotmovie.com para más información"> <i className="fa-brands fa-telegram fa-2xl"></i></a></div>
                <br/>
                <p id="carl"><strong>TERESA FORCADES: </strong>Doctor of Medicine, theologian from Harvard University and Spanish nun of the Order of Saint Benedict. Known worldwide for its strong opposition to the pharmaceutical industry.</p>
                
                
                

            </div>
        </div>
        );

    }
}

export default Protagonistas;













