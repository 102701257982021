import React, { Component } from 'react'

import Agend from '../assets/images/AGENDA.jpg'
import Objetivos from '../assets/images/OBJETIVOSODS.jpg'



class Agenda extends Component {
    render() {
        return (
            <div id="Agenda">
                <div className="col-xs-12">
                    <br />
                    <div className="w3-container w3-gray" id="LaAgenda">La Agenda</div>
                    <br/>
                    <p id="carl"><strong>Los filósofos transhumanistas consideran que el sujeto moderno está exterminando el planeta. Para ellos esto puede suceder sobre el año 2030.</strong> Más allá de esta década, el Nuevo Orden Mundial estaría formado por una pequeña élite que gozaría de todos los bienes físicos y materiales.<strong> Este panorama distópico ya se está empezando a dibujar y las élites globalistas lo han formalizado en una agenda, llamada precisamente Agenda 2030.</strong>
                    </p>
                    <img src={Agend} alt="Nature" className="responsive" id="carl" />
                    <h6>OBJETIVOS DE LA AGENDA 2030</h6>
                    <br />
                    <p id="carl"><strong>Bajo bonitas palabras como </strong> “El fin de la pobreza&quot; o “Hambre 0”,  y adornado con tonalidades coloridas <strong>se esconde una agenda que pretende cambiar todos los aspectos de nuestras sociedades libres </strong>en favor de unos pocos conglomerados privados. <strong>Estos son los 17 puntos de La Agenda traducidos a la realidad.</strong>
                    </p>
                    <br />
                    <img src={Objetivos} alt="Nature" className="responsive" id="grandeimg"/>
                    <br/>
                    <a href={Objetivos} download>
                        Descargar
                    </a>
                    <br /> <br />
                    <p id="carl"><strong>Este Nuevo Orden Mundial tiene varios nombres: Gran Reseteo, Agenda 2030, Ecologismo, Globalismo, Transhumanismo...</strong> pero todos responden a un mismo programa dirigido a implantar un nuevo modelo de sociedad bajo el control de las élites globalistas.</p>

                </div>
            </div>
        );

    }
}

export default Agenda;