//import React, { useState, useEffect } from 'react';
//import axios from 'axios';
//import { Config } from '../Config';
import React, { useState} from 'react';
import "../assets/css/ProgressBar.css";
import { ProgressBar } from './paymentBox/ProgressBar';

export const TotalRecaudado = () => {
  /*const [totalPagosRecibidos, setTotalPagosRecibidos] = useState(0);

  useEffect(() => {
    async function fetchTotalPagosRecibidos() {
      try {
        const response = await axios.get(`${Config.cmsUrl}/v1/progress`);
        setTotalPagosRecibidos(response.data.totalAmountReceived);
      } catch (error) {
        // console.error(error);
      }©
    }

    fetchTotalPagosRecibidos();
  }, 

  []);*/
 
  const valorStripe = 40946.5;

  // Establecer el objetivo en euros (120,000 €)
  const objetivoEnEuros = 120000;

  // Calcular el porcentaje en función del objetivo y el total de pagos recibidos en euros
  //const porcentaje = (totalPagosRecibidos / objetivoEnEuros) * 100;
   const porcentaje = (valorStripe / objetivoEnEuros) * 100;

  
  return (
    <div id="countdown-wrap">
      <div id="goals-wrap">
        <div id="Total-palabra">Total Recaudado</div>
        {/*<div id="goal">{totalPagosRecibidos.toFixed(2)} €</div>*/}
        <div id="goal">{valorStripe.toFixed(2)} €</div>
      </div>

      <ProgressBar progress={porcentaje} />

      <div className="goal-stat">
        <span className="goal-number">{porcentaje.toFixed(2)} % of Goal</span>
      </div>

     <div id="hashtag">#{objetivoEnEuros}</div>
    </div>
  );
}
