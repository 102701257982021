import React, { Component } from 'react'

import MODELOSEMPLEOIMG from '../assets/images/17.EMPLEODEFONDOS.jpg'
import CINESIMG from '../assets/images/18.ESTRENO-EN-CINES.jpg'
import WIMG from '../assets/images/19.W.jpg'



class Fondos extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LosFondos">¿Qué haremos con los fondos?</div>
                
                <br/>
                <p id="carl"><strong>Como cualquier producción audiovisual conllevará gastos en desplazamientos, alquiler de material audiovisual, espacios de grabación</strong> adecuados para cada localización <strong>además del posterior proceso de postproducción,</strong> animaciones, color, sonorización, doblajes en varios idiomas y el paso final; adaptación a formato cine.</p>
                <br />
                <img src={MODELOSEMPLEOIMG} alt="Nature" className="responsive" />
                <br />
                <br/>
                <p id="carl">Una vez acabada la aventura del rodaje y como hicimos con el documental anterior, The Big Reset Movie, estrenaremos el documental en cines. El objetivo de todo esto es llevar el mensaje al mayor número de gente posible.</p>
                <br />
                <img src={CINESIMG} alt="Nature" className="responsive" />
                <br />
                <div className="w3-container w3-gray" id="LosProtagonistas">¿Quienes somos?</div>
                <br/>
                <p id="carl"><strong>Somos gente como tú. Cansada de lo que nos cuenta las TV todos los días, solo que hemos decidido dejar de ser meros espectadores. La censura ha alcanzado niveles inimaginables, por eso, y por el bien del proyecto, es mejor que nos conozcas cómo W.</strong></p>
                <br />
                <img src={WIMG} alt="Nature" className="responsive" />
                <br /> <br />

            </div>
        </div>
        );

    }
}

export default Fondos;