import React, { Component } from "react";


class TrailerEng extends React.Component {

    render() {
        return (
            <div className="video">
                <iframe id="trailerwj" width="100%" height="100%" src="https://odysee.com/$/embed/@thebigreset:1/thebluedotmovie(ENG):5?r=Df4aqZPAtvWcZXmmoPMHxXLtnMTRup5r" allowFullScreen title="Dailymotion Video Player" > </iframe>
                
            </div>
        );
    }

}

export default TrailerEng;