import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";

import sinRecompensaImage from "../assets/images/1.SIN-RECOMPENSA.jpg";
import tuNombreYourName from "../assets/images/1.TU-NOMBRE-EN-LOS-CREDITOS.jpg";
import tuNombreYourNameLink from "../assets/images/3.TU-NOMBRE-+-LINK-.jpg";
import cinemaTicket from "../assets/images/4.CINEMA-TICKET-KICK.jpg";
import camisaBlueDot from "../assets/images/5.CAMISETA-KICK.jpg";
import bigReset from "../assets/images/6.CARTEL-TBR.jpg";
import packCompleto from "../assets/images/7.PACK-COMPLETO.jpg";
import sudaderaTBD from "../assets/images/8.SUDADERA-TBD-KICK.jpg";
import CamisaKick from "../assets/images/9.CAMISETA-KICK-+-ENTRADAS.jpg";
import packCompletoSudadera from "../assets/images/10.PACK-COMPLETO-+-SUDADERA.jpg";
import logroDocumental from "../assets/images/TU-LOGO-EN-EL-DOCUMENTAL.jpg";

import { DonateBox1 } from "./paymentBox/DonateBox";
import { DonateBox2 } from "./paymentBox/DonateBox";
import { DonateBox3 } from "./paymentBox/DonateBox";
import { DonateBox4 } from "./paymentBox/DonateBox";
import { DonateBox5 } from "./paymentBox/DonateBox";
import { DonateBox6 } from "./paymentBox/DonateBox";
import { DonateBox7 } from "./paymentBox/DonateBox";
import { DonateBox8 } from "./paymentBox/DonateBox";
import { DonateBox9 } from "./paymentBox/DonateBox";
import { DonateBox10 } from "./paymentBox/DonateBox";
import { DonateBox11 } from "./paymentBox/DonateBox";

import { useState } from "react";

// DonateBox
const handleDonate = () => {
	const donationUrl =  `https://donate.stripe.com/00gcQL5aEdGU7qobIS`;
	// const donationUrl =  `https://donate.stripe.com/test_00g8x84magdgdIA8wA`;
	window.location.href = donationUrl;
};

// Function to handle font size change by screen size
const handleFontSizeChange = (theme) => ({
	[theme.breakpoints.down("sm")]: {
	  fontSize: "16px",
	  fontWeight: "500",
	},
	[theme.breakpoints.up("md")]: {
	  fontSize: "19px", 
	  fontWeight: "500",
	},
  });

const MuiAccordion = () => {
	// Info from DonateBox
	const [expanded, setExpanded] = useState(false);

	const handleChange = (isExpanded, panel) => {
		setExpanded(isExpanded ? panel : false);
	};

	const theme = useTheme();

	return (
		<div className="wrapper">
			

			{/* DonateBox */}
			<Accordion
				expanded={expanded === "panel1"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel1")}
			>
				<AccordionSummary
					id="panel1-header"
					aria-controls="panel1-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Contribuir Sin Recompensa</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div className="accordionBorder">
						<img
							src={sinRecompensaImage}
							alt="Sin Recompensa"
							className="recompensa-image"
						/>
					</div>
					<div>
						<DonateBox1 
							handleDonate={handleDonate}
						/>
					</div>
					<div className="bgApoya">
						<Typography variant="subtitle1" className="pdTopMessage">
							<strong>Apóyalo porque crees en el.</strong>
						</Typography>
						<Typography>
							Apoya el proyecto sin recompensa, simplemente porque te resulta
							interesante.
						</Typography>
					</div>
				</AccordionDetails>
			</Accordion>
			
			{/* DonateBox2 */}
			<Accordion
				expanded={expanded === "panel2"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel2")}
			>
				<AccordionSummary
					id="panel2-header"
					aria-controls="panel2-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Tu nombre en los créditos </Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={tuNombreYourName}
							alt="Tu nombre en los créditos"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox2 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox3 */}
			<Accordion
				expanded={expanded === "panel3"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel3")}
			>
				<AccordionSummary
					id="panel3-header"
					aria-controls="panel3-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography  sx={handleFontSizeChange(theme)}>Tu nombre en los créditos + link</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={tuNombreYourNameLink}
							alt="Tu nombre en los créditos + link"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox3 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox4 */}
			<Accordion
				expanded={expanded === "panel4"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel4")}
			>
				<AccordionSummary
					id="panel4-header"
					aria-controls="panel4-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)} >2 invitaciones al estreno en cines</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={cinemaTicket}
							alt="2 invitaciones al estreno en cines"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox4 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox5 */}
			<Accordion
				expanded={expanded === "panel5"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel5")}
			>
				<AccordionSummary
					id="panel5-header"
					aria-controls="panel5-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography  sx={handleFontSizeChange(theme)}>Camiseta The Blue Dot</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={camisaBlueDot}
							alt="Camiseta / T-Shirt The Blue Dot"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox5 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox6 */}
			<Accordion
				expanded={expanded === "panel6"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel6")}
			>
				<AccordionSummary
					id="panel6-header"
					aria-controls="panel6-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography  sx={handleFontSizeChange(theme)}>Cartel Original The Big Reset Movie </Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={bigReset}
							alt="Cartel Original The Big Reset Movie"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox6 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox7 */}
			<Accordion
				expanded={expanded === "panel7"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel7")}
			>
				<AccordionSummary
					id="panel7-header"
					aria-controls="panel7-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography  sx={handleFontSizeChange(theme)}>Pack Completo Cines</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={packCompleto}
							alt="Pack Completo Cines"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox7 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox8 */}
			<Accordion
				expanded={expanded === "panel8"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel8")}
			>
				<AccordionSummary
					id="panel8-header"
					aria-controls="panel8-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography  sx={handleFontSizeChange(theme)}>Sudadera The Blue Dot</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={sudaderaTBD}
							alt="Sudadera The Blue Dot"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox8 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox9 */}
			<Accordion
				expanded={expanded === "panel9"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel9")}
			>
				<AccordionSummary
					id="panel9-header"
					aria-controls="panel9-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Camiseta + 2 invitaciones al estreno en cines</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={CamisaKick}
							alt="Camiseta + 2 invitaciones al estreno en cines"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox9 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox10 */}
			<Accordion
				expanded={expanded === "panel10"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel10")}
			>
				<AccordionSummary
					id="panel10-header"
					aria-controls="panel10-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Pack Completo Sudadera/ Tu logo en el documental</Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={packCompletoSudadera}
							alt="Pack Completo II"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox10 />
				</AccordionDetails>
			</Accordion>

			{/* DonateBox11 */}
			<Accordion
				expanded={expanded === "panel11"}
				onChange={(event, isExpanded) => handleChange(isExpanded, "panel11")}
			>
				<AccordionSummary
					id="panel11-header"
					aria-controls="panel11-content"
					expandIcon={<ExpandMoreIcon />}
				>
					<Typography sx={handleFontSizeChange(theme)}>Tu Logo </Typography>
				</AccordionSummary>
				<AccordionDetails>
				<hr/>
					<div>
						<img
							src={logroDocumental}
							alt="Pon Tu Logo en el Documental"
							className="DonateImageSize"
						/>
					</div>
					<DonateBox11 />
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

export default MuiAccordion;
