
// import { loadStripe  } from "@stripe/stripe-js"
// import { Config } from "../../Config";
import {Typography} from "@mui/material";


// let stripePromise;
// const getStripe = async () => {
//     if (!stripePromise) {
//         const stripe = await loadStripe(`${Config.pubKey}`);
//         console.log("Stripe Publishable Key:", stripe);
//         stripePromise = Promise.resolve(stripe);
//     }
//     return stripePromise;
// };


// Donate Boxes
export const DonateBox1 = ({ handleDonate }) => {
	return (
		<div className="donate-box">
			<Typography className="inputDonationTitle">
            Make your contribution here
			</Typography>
			<button
				id="btn-colabora"
				className="button button2"
				onClick={handleDonate}
			>
				Donation
			</button>
		</div>
	);
};

export const DonateBox2 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/6oE3gbauY0U83a8154`;
    }
    

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Appearance in the documentary credits</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>5 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>You will appear as a patron of the project in the credits of the documentary.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                           {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Approximate delivery</p>
                            <p className="cardBackers2">Dic 2024</p>
                        </div>
                    </div>
                    <div className="carDivision">
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribuye 5 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox3 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/9AQ9Ez8mQauI7qo7tr`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Appearance in credits &nbsp; &nbsp; &nbsp; + Digital copy</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>15 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>You will appear as a patron in the credits of the documentary and we will send you a link to see the film from its online premiere.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Approximate deliver</p>
                            <p className="cardBackers2">Dic 2024</p>
                        </div>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 15 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox4 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/cN26sn5aEdGUh0Y00Y`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">invitation Premiere in cinemas + Talks</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>30 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>You will have two tickets to see the documentary in theaters. Where you can ask questions to some of the protagonists of the documentary.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Spain only</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 30 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox5 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/6oEeYTcD6fP2eSQ295`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">T-Shirt The Blue Dot</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>30 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>T-shirt with the QR code directly to the documentary so you can carry the message everywhere you go.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Only in certain countries</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>15 days</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 30 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox6 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/dR6183eLe32g4ecaFA`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">The big reset cinema poster signed by W</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>40 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>You will receive the original poster that was used in the theaters where the previous documentary, The Big Reset Movie, was shown, with your name on it and signed by director W.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Only in certain countries</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>15 days</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 40 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox7 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/14k03Z5aEbyMh0YcNH`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Complete Pack</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>50 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Invitation for two people to the cinema premiere where some of the protagonists will be able to attend + appear in the credits + link to watch the documentary before its online premiere.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                           {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Spain only</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 50 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox8 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/5kA1835aE46k5igcNG`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">The Blue Dot Sweatshirt</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>50 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Sweatshirt with the QR code directly to the documentary so you can carry the message wherever you go.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Only in certain countries</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>15 days</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 50 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox9 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://donate.stripe.com/fZe9EzgTmdGU5ig5ld`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">T-shirt + Invitation to the Cinema Premiere</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>60 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>The Blue Dot T-shirt + 2 Invitations to the cinema premiere, where some of the protagonists of the documentary may attend.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Spain only</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 60 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox10 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3cs2c71YsfP28useVM`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Complete Pack + Sweatshirt</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>100 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>The Blue Dot Sweatshirt + 2 Invitations to attend the premiere of the documentary where some of the protagonists of the documentary may attend + Link to watch the documentary before its online premiere + Appear in the credits.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                        <div className="carDivision">
                            <p className="cardBackers1 cardBackers2">Send to</p>
                            <p className="cardBackers2">Spain only</p>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 100 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

export const DonateBox11 = () => {
   const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIY5oj9qU1Yc7qo4h7`;
    }

    return (
        <div className="cardCont">
                    <div className="cardContenedor">
                        <div className="carDivision"><p className="cardTitle">Put Your Logo in the Documentary</p></div>
                        <div className="carDivision">
                            <div className="cardValue">
                                <div>500 <i className="fa-solid fa-euro-sign euro-sign "></i></div>
                            </div>
                        </div>
                    </div>
                    <div className="carDivision">
                        <p>Reward aimed at organizers or groups. Your logo will appear in the film, on the project website and in the communications we make in the media as the official sponsor of the documentary.</p>
                    </div>
                    <div className="cardContenedor">
                        <div className="carDivision">
                            {/* <p className="cardBackers1">Backers</p>
                            <p>0</p>
                        */}
                        </div>
                    </div>
                    <div className="carDivision">
                        <p className="cardBackers1">Approximate Delivery</p>
                        <p>Dic 2024</p>
                    </div>
                    <div className="carDivision">                  
                            <button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Contribute 500 <i className="fa-solid fa-euro-sign euro-sign"></i></button>
                    </div>
                </div>
    )
}

// Tickets
// Function to handle Personal
export const TicketBox1 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/fZe8Av0UocCQbGE5kR`;
    }
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">15 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
						<p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1 person</strong> can watch The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox2 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIYdUP6eIcCQdOMdRm`;
    }
   
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">30 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>2 people</strong> you can watch The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox3 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/28o03Zbz2dGUdOM00y`;
    }

    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">45 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>3 people</strong> you can watch The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox4 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/9AQaID0UoeKY2643dd`;
    }

    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">60 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>4 people</strong> you can watch The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox5 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/14keYTcD6eKY9yw7t1`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">75 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>5 people</strong> you can watch The Blue Dot Movie </p>
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox6 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/dR68AvauYauIbGE4gQ`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">105 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text">
                            <i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> 
                            <strong>7 people</strong> you can watch The Blue Dot Movie 
                        </p>
                       
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox7 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/bIYcQLbz2byM7qocNn`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">150 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>10 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox8 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/fZecQL9qU8mA6mk00C `;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">225 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>15 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox9 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/9AQ03ZdHa32g4ecfZB`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">300 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>20 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox10 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3cs2c75aEbyM12014I`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">525 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>35 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}


// Ticket hanldle Cines
export const TicketBox11 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3cs2c75aEbyM12014I`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">525 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>35 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox12 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/6oEaIDfPi9qEfWUeVz`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">1,125 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>75 people</strong> you can watch The Blue Dot Movie </p>

					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox13 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/3csaID5aE32g7qoeVA`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">1,500 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>100 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox14 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/14k8AveLeeKY2644gX`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">3,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>200 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox15 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/6oE7wrdHabyMcKI7ta`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">5,250 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>350 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}


// Ticket handle Grupos
export const TicketBox16 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/eVa2c7bz2byMbGE28R`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">11,250 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>750 people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox17 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/5kA03ZdHa7iw4ec4h0`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">15,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1.0k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox18 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/aEUaIDfPi8mA9yw4h1`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">22,500 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>1.5k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox19 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/eVa3gbdHacCQ6mk5l6`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">30,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>2.0k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox20 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/5kAaIDeLe9qEaCA3cZ`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">45,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>3.0k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox21 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/8wMeYTeLe1Yc8usfZM`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">60,000<i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>4.0k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}

export const TicketBox22 = () => {
    const redirectToCheckout = async () => {
        window.location.href = `https://buy.stripe.com/7sI8AvfPi0U8h0Y7th`;
    }
    
    return (
		<div>
			<div className="container-ticket">
				<div className="right-labels header-ticket-card">
					<span className="ticket-value">75,000 <i className="fa-solid fa-euro-sign euro-sign "></i> </span>
					<span>
                        <p className="ticket-text"><i className="fa-solid fa-circle-check fa-beat circle-check-color"></i> <strong>5.0k people</strong> you can watch The Blue Dot Movie </p>
                        
					</span>
				</div>
				<div className="left-button  ">
					<button className="cardBtn cardBtnColor2" onClick={redirectToCheckout}>Choose </button>
				</div>
			</div>
		</div>
    );
}