import '../assets/css/App.css'
import { useState } from 'react'


export default function Faqs() {

    const [selected, setSelected] = useState(null)


    const toggle = i => {
        if (selected === i) {
            return setSelected(null)
        }

        setSelected(i)
    }



    return (
        <div id='Faqs'>
            <div className='wrapper'>

                <div className='Preguntasfreq'>Preguntas Frecuentes</div>
                <div className="accordionFaqs">

                    {Faqdata.map((item, i) => (
                        <div className='item'>
                            <div className='header' onClick={() => toggle(i)}>
                                <h5><strong>{item.titulo}</strong></h5>
                                <span>{selected === i ? '-' : '+'}</span>
                            </div>
                            <div className={selected === i ? 'body show' : 'body'}>
                                <p>{item.contenido}</p>
                                <a href={item.enlace}><p><u>{item.url}</u></p></a>
                            </div>
                        </div>
                    ))}

                </div>
            </div>

        </div>
    )

}

const Faqdata = [
    {
        titulo: '¿Qué es The Blue Dot?',
        contenido: 'The Blue Dot o El Punto Azul es la secuela del documental The Big Reset Movie,ampliamente censurado en todo el mundo por explicar a fondo todos los acontecimientos que hemos vivido desde 2020. El objetivo de este documental es seguir informando a la ciudadanía lo que realmente está pasando en el planeta.',
        
    },
    {
        titulo: '¿De qué maneras puedo ayudar a este proyecto?',
        contenido: 'Tu participación en la difusión de este documental es muy importante. Sin la colaboración de miles de personas en todo el mundo, este proyecto sería del todo imposible. Puedes encontrar todas las formas de difundir este mensaje aquí.',
        url: 'Como ayudar a difundir',
        enlace: 'https://thebluedotmovie.com/como-ayudar',
    },
    {
        titulo: '¿Cómo se determina el precio la invitación a los cines?',
        contenido: 'El precio de las invitaciones a los cines en España seguirá siendo de 15€ por persona. Para poder proyectar este documental hay que alquilar cine a cine, sala a sala. Así se hizo en el anterior proyecto, The Big Reset Movie, puedes verlo todo aquí (@thebluedotmovie). The Blue Dot Movie nunca pasaría el filtro de cultura porque depende de los mismos         organismos que llevan manipulando a la sociedad desde 2020.         Una parte de las aportaciones de las entradas de cine van dirigidas a los gastos de         producción del documental, otra al alquiler de la sala y personal: seguridad, encargados de los accesos a la sala, fotógrafos y equipo audiovisual. Además, con esas aportaciones se cubren los desplazamientos y hotel de los protagonistas, que dependiendo de su disponibilidad, puedan asistir a las proyecciones que se puedan agendar en diversas ciudades.',
    },
    {
        titulo: '¿Cómo se utilizará el dinero Pay It Forward?',
        contenido: 'Hemos seguido el modelo de adquirir invitaciones a los cines para otras personas que han implementado en Estados Unidos la productora Angel Studios y que tan bien les ha funcionado para su película Sound of Freedom, que ha logrado batir todos lo récords en taquilla siendo la película más vista en USA en cines en 2023, a pesar de que está siendo fuertemente censurada. Vivimos momentos de cambio y ésta es una manera de preguntarle a la sociedad qué es lo que realmente quiere ver.',
    },
    {
        titulo: '¿Quién recibirá esas invitaciones sin coste?',
        contenido: 'Una vez acabado el rodaje del documental y siguiendo el modelo de alquiler de salas de cine que llevamos a cabo en el proyecto anterior, The Big Reset Movie, programaremos proyecciones a lo largo y ancho del territorio nacional. El objetivo es que haya pases en todas las ciudades, en todos los cines y todo el rato. Será cuando empiece esta fase en cines, cuando abriremos la vía para que aquellas personas que no tengan recursos para adquirir esas invitaciones puedan conseguirla sin coste a través de esta web.',
    },
    {
        titulo: '¿Cómo puede mi empresa u organización promocionar este documental?',
        contenido: 'Esa fue una de las maneras como conseguimos hacer realidad el proyecto anterior, la colaboración de empresas y organizaciones. En la sección colabora puedes encontrar la información de como poner tu logotipo en el documental. La película tendrá impacto mundial y millones de personas en todo el mundo verán el logotipo de tu empresa u organización como patrocinador de este proyecto.',
    },
    {
        titulo: '¿Quieres adquirir invitaciones de las proyecciones en cines para tu negocio, organización o empleados?',
        contenido: 'Cada persona cuenta. Tu empresa u organización puede ayudar a alcanzar la meta y que ese proyecto se haga una realidad. Escribemos a este email y te enviaremos una cotización.',
        url: 'Email : hola@thebigresetmovie.com ',
        enlace: 'mailto:hola@thebigresetmovie.com',
    },
    {
        titulo: '¿Cómo puedo organizar una proyección privada?',
        contenido: 'Una vez el documental se vaya a estrenar en cines se podrán organizar proyecciones privadas para grupos u organizaciones. Puedes escribirnos a este mail y te daremos respuesta lo antes posible.',
        url: 'Email : hola@thebigresetmovie.com ',
        enlace: 'mailto:hola@thebigresetmovie.com',
    },
    {
        titulo: '¿Por qué hacemos el crowdfunding en esta página?',
        contenido: 'Si conoces el proyecto anterior, sabrás que el crowdfunding del documental The Big Reset Movie, se hizo en la plataforma de Kickstarter, la página de micromecenazgo número a nivel mundial. Esta vez The Blue Dot ha sido censurado también en Kickstarter. Curioso que nos permitieran en 2021 lanzar un proyecto sobre la psicopandemia, y en 2023 nos veten un documental que trata sobre la Agenda 2030. Según las normas de su comunidad, “No se permiten proyectos que incentiven discriminación o intolerancia hacia grupos marginales”. Una excusa obvia para no permitirnos llevarlo a cabo en su plataforma. Hay mucho dinero metido detrás de esa colorida Agenda.',
    },
    {
        titulo: '¿Cómo ayudar económicamente a acabar el proyecto y llevarlo a los cines?',
        contenido: 'Por ahora hemos rodado la primera parte del proyecto, con algunos protagonistas españoles. Pero para construir un relato global necesitamos ir a entrevistar a profesionales en muchos campos repartidos en diferentes países. Por eso es necesaria tu colaboración y la de miles de personas en todo el mundo. Las aportaciones van desde 1€ a lo que tú consideres que puedes aportar para poder hacer realidad este proyecto.',
    },
    {
        titulo: '¿Cuándo se estrenará el documental?',
        contenido: 'La fecha de estreno del documental es orientativa, depende mucho de que podamos agendar entrevistas con personas de difícil acceso, muchas de ellas amenazadas de muerte. Por lo tanto, no es un trabajo fácil dar la fecha exacta de su estreno. La idea es poder proyectarlo a finales de 2024. Si bien iremos informando de los avances del documental a través del correo electrónico que nos proporcionéis todos los patrocinadores. Muchas gracias por creer en todo esto. ;)',
    },

    {
        titulo: '¿Dónde puedo ver The Big Reset Movie?',
        contenido: 'La censura que ha sufrido y sigue sufriendo el anterior documental es brutal. Youtube, Vimeo y plataformas similares lo eliminan al instante. Hubo momentos  en 2022 en los que no se podía poner el nombre de la web en publicaciones de Instagram, ni siquiera en mensajes privados entre personas. Los borraban. Antes tenían una buena excusa, un virus asesino flotaba entre la población. Y ahora que ese virus supuestamente ya no está, ¿por qué lo siguen censurando?. La respuesta es muy sencilla. Toda esta historia nunca ha ido sobre un virus. Puedes ver el documental íntegro en ESP/ING/FR y sacar tus propias conclusiones en la página oficial del proyecto.',
        url: 'Ver: Web The Big Reset',
        enlace: 'https://thebigresetmovie.com',
    }

]
