import React, { Component } from 'react'

import Dictaduraecologicaimg from '../assets/images/LADICTADURAECOLÓGICA.jpg'
import Escenariosimg from '../assets/images/ESCENARIOSCC.jpg'



class DictaduraecologicaEng extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Ladictaduraecologica">The ecological dictatorship</div>
                
                <br/>
                <img src={Dictaduraecologicaimg} alt="Nature" className="responsive" />
                <h6>KLAUS SCHWAB AT A WEF MEETING</h6>
                <br/>
                <p id="carl">Orchestrated by the ruling elites and again with the WEF at the helm, this movement has become a big business in itself. Underpinned by the fallacy that CO2 is the cause of global warming, the WEF's "My carbon" policy targets an unimaginable number of restrictions that threaten free societies.<strong> In the documentary we will show the scenarios that, if we do nothing to remedy it, have designed for us</strong></p>
                <br/>
                <img src={Escenariosimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">This green agenda program is inspired by the tyrannical days of COVID lockdowns.<strong> Coercive mobility plans, Digital Identity, ban on cash and programmable currencies, along with the Net Zero carbon tax policy, would turn our daily lives into an Orwellian scenario.</strong></p>
            </div>
        </div>
        );

    }
}

export default DictaduraecologicaEng;