import React, { Component } from 'react'

import Falsapandemiaimg from '../assets/images/Falsapandemia.jpg'



class Falsapandemia extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="LaFalsapandemia">La Falsa Pandemia</div>
                
                <br/>
                <img src={Falsapandemiaimg} alt="Nature" className="responsive" />
                <h6>BILL GATES Y SU LIBRO “COMO PREVENIR LA PRÓXIMA PANDEMIA"</h6>
                 <br/>
                <p id="carl"><strong>Enmarcado en este plan globalista, la psicopandemia se presentó como un “agente acelerador&quot;.</strong> Estos nuevos gobernantes sin legitimidad democrática ninguna, ahora reclaman el derecho a gobernar al pueblo en nombre de la salud y la seguridad universales, basándose en presuntas pruebas científicas.<strong> El plan no les salió redondo, pero seguro lo volverán a intentar.</strong></p>

            </div>
        </div>
        );

    }
}

export default Falsapandemia;