import React, { Component } from 'react'

import Transhumanismoimg from '../assets/images/TRANSHUMANISMO.jpg'



class Transhumanismo extends Component {
    render() {
        return (
            <div id="Falsapandemia">
            <div className="col-xs-12">
                <br />
                <div className="w3-container w3-gray" id="Eltranshumanismo">El transhumanismo</div>
                
                <br/>
                <img src={Transhumanismoimg} alt="Nature" className="responsive" />
                <br /> <br/>
                <p id="carl">Las Agendas 2030 y Avatar 2045 son proyectos que pretenden una transformación no solo de los sistemas económicos, políticos y culturales sino también los religiosos, la naturaleza y la modificación del propio ser humano a nivel genómico; lo que hace del transhumanismo el eje de esta Agenda.<strong> Este proceso, conocido como cuarta Revolución Industrial es el anticipo de una era que pretende sustituir a los hombres por máquinas.</strong></p>

            </div>
        </div>
        );

    }
}

export default Transhumanismo;